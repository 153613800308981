import React, { useState } from 'react';
import './LandingPage.css'; // Ensure your CSS styles are in place
import backgroundImage from '../../assets/background.png';
import logoImage from '../../assets/ilumina.png';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

function LandingPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  localStorage.clear();

  const handleGetStarted = () => {
    navigate('/signup');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setAuthError('');

    console.log(`Making API call to: ${apiBaseUrl}/users/validate-credentials`);

    try {
        const response = await axios.post(
            `${apiBaseUrl}/users/validate-credentials`,
            { username, password },
            { withCredentials: true }  // Include cookies
        );
        //localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('userID', response.data.userID);
        navigate('/subject-selection');
    } catch (error) {
      setAuthError('Invalid username or password');
    }
  };

  const navigateToResetPassword = () => {
    navigate('/reset'); // Adjust the route as necessary
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="landing-container">
      <div className="top-container">
        <img src={logoImage} alt="iLUMINA Logo" className="top-right-logo" />
        <div
          className={`background-image ${isHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="image-container" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
          <div className="text-container">
            <div className={`overlay-text ${isHovered ? 'faded' : ''}`}>
              Experience the joy of learning with
            </div>
            <div className={`overlay-two ${isHovered ? 'faded' : ''}`}>
                ilumina
            </div>
            <div className={`hover-overlay-text ${isHovered ? 'text-visible' : ''}`}>
                <h2>Discover a new horizon of learning with ilumina.</h2>
                <p>
                  ilumina is not just another educational platform; it's a revolution in personalized learning.
                </p>
                <p>
                  Welcome to the future of learning, where every lesson is a step towards personal excellence, crafted just for you.
                </p>
            </div>
          </div>
        </div>
        <div className="login-container">
          <input
            type="text"
            placeholder="Username"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              name="current-password"
              placeholder="Password"
              className="login-input password-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i onClick={togglePasswordVisibility} className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon-confirm`}></i>
          </div>
          <button className="login-button" onClick={handleSignIn}>Sign In</button>
          {authError && (
            <>
              <div className="error-message">{authError}</div>
              <button onClick={navigateToResetPassword} className="reset-password-link">Reset Password</button>
            </>
          )}
          <div className="separator">OR</div>
          <button className="get-started-button" onClick={handleGetStarted}>Get Started</button>
        </div>
      </div>
      <div className="footer">
        <div className="footer-links">
          <Link to="/about" className="about-link">About Us</Link>
          <Link to="/help" className="about-link">Help</Link>{/* Adjusted Link position */}
          <Link to="/select-plan" className="about-link">Pricing</Link>{/* Adjusted Link position */}
        </div>
        <div className="social-links">
          <a href="https://www.facebook.com/people/Ilumina/61558095401415/?mibextid=JRoKGi" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/iluminaed/?igsh=MTliaWx5enM3MzJmcA%3D%3D" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;







