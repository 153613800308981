import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css';
import axios from 'axios';

function SignUpPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstname, setFirst] = useState('');
  const [lastname, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [agreementError, setAgreementError] = useState('');
  const [ageError, setAgeError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (username) {
        setIsCheckingUsername(true);
        axios.get(`${apiBaseUrl}/users/check-username/${username}`)
          .then(response => {
            setIsCheckingUsername(false);
            setUsernameError('');
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              setUsernameError('Username is already taken');
            }
            setIsCheckingUsername(false);
          });
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [username]);

  const isValidEmail = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const isValidPassword = (password) => {
    // This regex checks for at least one uppercase letter, one lowercase letter, one number, and any character that is not a letter or digit (considered as a special character).
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    localStorage.clear();
    setPassword(passwordRef.current.value);
    setConfirmPassword(confirmPasswordRef.current.value);
    setEmail(emailRef.current.value);
    setAgreementError('');

    const yearOfBirthValue = parseInt(yearOfBirth, 10);
    const currentYear = new Date().getFullYear();

    // Prevent submission if agreement not checked
    if (!isAgreementChecked) {
      setAgreementError('Accept User Agreement');
      return;
    }

    if (isNaN(yearOfBirthValue) || currentYear - yearOfBirthValue < 14) {
      setAgeError('You must be at least 14 years old to sign up.');
      return;
    } else {
      setAgeError('');
    }

    if (!usernameError && !passwordError && !confirmPasswordError && !emailError && !isCheckingUsername && !ageError) {
      const userData = {
        username,
        email,
        password,
        year_of_birth: yearOfBirth
      };

      try {
        console.log(`${apiBaseUrl}/users/`);
        const response = await axios.post(`${apiBaseUrl}/users/`, userData, {
          withCredentials: true
        });
        const { id } = response.data;

        // Store the user ID for future reference
        localStorage.setItem('userID', id);

        // Redirect to the payment page
        navigate('/select-plan');
      } catch (error) {
        console.error('Error creating user:', error);
      }
    }
  };


    // Toggles for password and confirm password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="signup-page">
      <form onSubmit={handleSignUp}>
        <h1>Welcome to iLumina!</h1>
        <p>We'll start by setting up your account username and password.</p>
        <div>
          <input type="text" placeholder="First Name" value={firstname} onChange={(e) => setFirst(e.target.value)} />
        </div>
        <div>
          <input type="text" placeholder="Last Name" value={lastname} onChange={(e) => setLast(e.target.value)} />
        </div>
        <div>
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          {usernameError && <div className="error-message">{usernameError}</div>}
        </div>
        <div className="input-container">
          <input
            ref={passwordRef}
            name="new-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onInput={(e) => console.log(e.currentTarget.value)}
            onBlur={() => isValidPassword(password)}
            className="password-input"
            autoComplete="new-password"
          />
          <i
            onClick={togglePasswordVisibility}
            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}
          ></i>
        </div>
        {passwordError && <div className="error-message">{passwordError}</div>}
        <div className="input-container">
          <input
            ref={confirmPasswordRef}
            name="new-password"
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onInput={(e) => console.log(e.currentTarget.value)}
            onBlur={() => setConfirmPasswordError(password !== confirmPassword ? "Passwords don't match." : '')}
            className="password-input"
            autoComplete="new-password"
          />
          <i
            onClick={toggleConfirmPasswordVisibility}
            className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}
          ></i>
        </div>
        {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
        <div>
          <input ref={emailRef} name="email" type="email" autoComplete="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={() => isValidEmail(email)} />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <div>
          <input
            type="number"
            placeholder="Year of Birth"
            value={yearOfBirth}
            onChange={(e) => setYearOfBirth(e.target.value)}
            max={new Date().getFullYear()}
          />
          {ageError && <div className="error-message">{ageError}</div>}
        </div>
        <div className="agreement-container">
          <label className="agreement-label">
            <input
              type="checkbox"
              checked={isAgreementChecked}
              onChange={(e) => setIsAgreementChecked(e.target.checked)}
            />
            <span>
              I agree to the <a href="#" onClick={() => setIsModalOpen(true)}>User Agreement</a>
            </span>
          </label>
          {agreementError && <div className="error-message">{agreementError}</div>}
        </div>
        <button type="submit">Create Account</button>
      </form>
      {isModalOpen && (
        <div className="modal-agreement">
          <div className="modal-content-agreement">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2>User Agreement</h2>
            <iframe
              src="https://app.termly.io/document/terms-of-service/2b10bb5c-f650-4ab4-9d37-15e7697ad5d1"
              title="User Agreement"
              style={{ width: '100%', height: '400px', border: 'none' }}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpPage;

