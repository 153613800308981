import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function useInactivityTimer(timeout = 1200000) { // Set to 20 minutes
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  let timer;

  const refreshToken = async () => {
    try {
      await axios.post(
        `${apiBaseUrl}/users/refresh-token`,
        {},
        {
          withCredentials: true
        }
      );
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout(); // Optionally log out the user if refresh fails
    }
  };

  const resetTimer = () => {
    clearTimeout(timer);
    refreshToken();
    timer = setTimeout(logout, timeout);
  };

  const logout = () => {
    setShowLogoutModal(true);

    setTimeout(() => {
      localStorage.clear();
      setShowLogoutModal(false);
      navigate('/'); // Redirect to login page
    }, 3000); // Adjust the delay time as needed
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keypress', resetTimer);
    document.addEventListener('click', resetTimer);

    resetTimer(); // Start the initial timer

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keypress', resetTimer);
      document.removeEventListener('click', resetTimer);
    };
  }, []);

  return showLogoutModal;
}

export default useInactivityTimer;

