import React from 'react';
import './SubjectSelectionPage.css';
import logoImage from '../../assets/white_logo.png'; // Replace with the path to your logo image
import UserIcon from '../shared/UserIcon'; // Import the UserIcon component
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import mathImage from '../../assets/Math.png'; // Replace with the path to your image
import historyImage from '../../assets/History.png'; // Replace with the path to your image
import literatureImage from '../../assets/Literature.png'; // Replace with the path to your image
import scienceImage from '../../assets/Science.png'; // Replace with the path to your image
import {Link, useNavigate} from 'react-router-dom';

function SubjectSelectionPage() {
  const navigate = useNavigate();
  const showLogoutModal = useInactivityTimer()

  const goToMathPage = () => {
    navigate('/math'); // Assuming /math is the route for the MathPage
  };

  const goToHistoryPage = () => {
    navigate('/history'); // Assuming /math is the route for the MathPage
  };

  const goToLitPage = () => {
    navigate('/literature'); // Assuming /math is the route for the MathPage
  };

  const goToSciencePage = () => {
    navigate('/science'); // Assuming /math is the route for the MathPage
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="subject-selection-page">
      {showLogoutModal && <LogoutModal />}
      <div className="subject-header">
        <img src={logoImage} alt="iLUMINA Logo" className="subject-logo" onClick={goToSubject}/>
        <h2>What do you want to learn today?</h2>
        <UserIcon className="user-profile" onClick={goToUserPage}/> {/* Add UserIcon component here */}
      </div>
      <div className="subjects-grid">
        <div className="subject-tile" style={{ backgroundImage: `url(${mathImage})` }} onClick={goToMathPage}></div>
        <div className="subject-tile" style={{ backgroundImage: `url(${historyImage})` }} onClick={goToHistoryPage}></div>
        <div className="subject-tile" style={{ backgroundImage: `url(${literatureImage})` }} onClick={goToLitPage}></div>
        <div className="subject-tile" style={{ backgroundImage: `url(${scienceImage})` }} onClick={goToSciencePage}></div>
      </div>
      <div className="footer">
        <Link to="/about" className="ai-link">Learn more about our use of AI</Link>
      </div>
    </div>
  );
}

export default SubjectSelectionPage;


