import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserPage.css';
import UserIcon from '../shared/UserIcon';
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png';
import { history_lessons } from '../LessonSelectionPages/HistoryPage';
import { math_lessons } from '../LessonSelectionPages/MathPage';
import { science_lessons } from '../LessonSelectionPages/SciencePage';
import { lit_lessons } from '../LessonSelectionPages/LitPage';
import axios from 'axios';

function UserPage() {
  const showLogoutModal = useInactivityTimer()
  const [userName, setUserName] = useState('');
  const [userActivities, setUserActivities] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState({});
  const [childUsername, setChildUsername] = useState('');
  const [childPassword, setChildPassword] = useState('');
  const [childrenProfiles, setChildrenProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showProfiles, setShowProfiles] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [totalLessons, setTotalLessons] = useState(0);
  const [uniqueLessons, setUniqueLessons] = useState(0);
  const [difficulty, setDifficulty] = useState('All');
  // Add a state to hold the family plan status
  const [isFamilyPlan, setIsFamilyPlan] = useState(true);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;


  // Combine lessons data from multiple subjects
  const lessonsData = {
    ...history_lessons,
    ...math_lessons,
    ...science_lessons,
    ...lit_lessons
    // Add other subjects here...
  };


  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem('userID'); // Retrieve userID from cookie
      console.log(userId)
      try {

        const { data } = await axios.get(`${apiBaseUrl}/users/${userId}/user-profile`, {
          withCredentials: true
        });
        console.log('Other data', data)
        setUserRole(data.role);
        setUserName(data.firstname);
        setUserActivities(organizeActivities(data.activities, difficulty));
        setTotalLessons(data.total_lessons);
        setUniqueLessons(data.unique_lessons);
        setIsFamilyPlan(data.is_family_plan);

        const { data: children } = await axios.get(`${apiBaseUrl}/users/${userId}/children`, {
         withCredentials: true
        });
        setChildrenProfiles(children);
      } catch (error) {
        console.error('Error fetching user data', error);
      }

    };

    fetchUserData();
  }, [difficulty]);

    /**
   * Count the total number of lessons in a given main category.
   * @param {string} mainCategory - The main category name.
   * @returns {number} The total number of lessons.
   */
  const countTotalLessonsInMainCategory = (mainCategory) => {
    // Access subcategories in the specified main category
    const subcategories = lessonsData[mainCategory]?.subcategories || {};

    let totalLessonsCount = 0;

    // Iterate through each subcategory and count its lessons
    Object.values(subcategories).forEach((lessonsList) => {
      totalLessonsCount += lessonsList.length;
    });

    return totalLessonsCount;
  };

  const organizeActivities = (activities, selectedDifficulty) => {
    const organized = {};

    Object.entries(activities).forEach(([mainCategory, subCategories]) => {
      const filteredSubCategories = {};

      // Use the total number of lessons in the main category

      Object.entries(subCategories).forEach(([subCategory, lessons]) => {
        // Use the total number of lessons in the main category
        const totalLessons = countTotalLessonsInMainCategory(subCategory);
        // Filter and count distinct lessons that have been completed with an assessment in the selected difficulty
        const completedLessonsList = Object.entries(lessons)
          .filter(([, details]) => selectedDifficulty === 'All'
            ? Object.values(details).some(detail => detail.completed)
            : details[selectedDifficulty]?.completed)
          .map(([lessonName, details]) => ({
            name: lessonName,
            details: details
          }));

        const completedLessonsCount = completedLessonsList.length;

        // Calculate the completion percentage
        const completionPercentage = totalLessons > 0 ? (completedLessonsCount / totalLessons) * 100 : 0;

        // Log the subcategory and its completion percentage
        //console.log(`SubCategory: ${subCategory}, Completion Percentage: ${completionPercentage}, Completed Lessons: ${completedLessonsCount}, Total Lessons: ${totalLessons}`);

        // Only add subcategories with completed lessons
        if (completedLessonsCount > 0) {
          filteredSubCategories[subCategory] = {
            lessons: completedLessonsList,
            completionPercentage
          };
        }
      });

      // Only add main categories with subcategories that have completed lessons
      if (Object.keys(filteredSubCategories).length > 0) {
        organized[mainCategory] = filteredSubCategories;
      }
    });

    return organized;
  };

  const toggleOpen = (category, subCategory) => {
    setIsOpen(prevState => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [subCategory]: !(prevState[category] && prevState[category][subCategory])
      }
    }));
  };

  const handleAddChild = async (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem('userID');
    try {
      await axios.post(`${apiBaseUrl}/users/${user_id}/add-child`, {
        username: childUsername,
        password: childPassword
      }, {
        withCredentials: true
      });
      alert('New profile added successfully!');
      setChildUsername('');
      setChildPassword('');
      setShowModal(false);

      // Refetch the child profiles
      const userId = localStorage.getItem('userID');
      const { data: children } = await axios.get(`${apiBaseUrl}/users/${userId}/children`, {
        withCredentials: true
      });
      setChildrenProfiles(children); // Update the state with the new list

    } catch (error) {
      console.error('Error adding child profile', error.response?.data);
      alert('Failed to add child profile.');
    }
  };

  const switchProfile = async (childId) => {
      try {

          localStorage.removeItem('userID');
          const response = await axios.post(`${apiBaseUrl}/switch-profile`, { user_id: childId }, {
            withCredentials: true
          });
          localStorage.setItem('userID', response.data.userID);
          // Force full page reload to user profile
          window.location.href = '/user-profile';
      } catch (error) {
          alert('Failed to switch profile');
      }
  };


  const renderLessons = (mainCategory, subCategory) => {
    const subCategoryData = userActivities[mainCategory]?.[subCategory] || {};
    const lessons = subCategoryData.lessons || [];
    const completionPercentage = subCategoryData.completionPercentage || 0;

    // Log the main category, subcategory, and completion percentage
    //console.log(`Main Category: ${mainCategory}, SubCategory: ${subCategory}, Completion Percentage: ${completionPercentage}`);

    return (
      <div key={subCategory} className={`activity-category ${isOpen[mainCategory]?.[subCategory] ? 'expanded' : ''}`}>
        <h3 onClick={() => toggleOpen(mainCategory, subCategory)}>
          {subCategory}
          <div>
              {Math.round(completionPercentage)}%
          </div>
        </h3>
        {isOpen[mainCategory]?.[subCategory] && <ul>{lessons.map(lesson => <li key={lesson.name}>{lesson.name}</li>)}</ul>}
      </div>
    );
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const filterActivitiesBySearchTerm = (activities, searchTerm, selectedDifficulty) => {
    return Object.entries(activities).reduce((acc, [category, subCategories]) => {
      const filteredSubCategories = Object.entries(subCategories).reduce((subAcc, [subCategory, lessonsData]) => {
        const lessons = Array.isArray(lessonsData.lessons) ? lessonsData.lessons : [];

        const filteredLessons = lessons.filter(lesson => {
          const lessonDetails = lesson.details;
          // Check if the lesson name includes the search term (case-insensitive)
          const matchesSearch = lesson.name.toLowerCase().includes(searchTerm.toLowerCase());

          // Check if the lesson matches the selected difficulty or if it's "All"
          const matchesDifficulty = selectedDifficulty === 'All' || (lessonDetails[selectedDifficulty]?.completed || false);

          return matchesSearch && matchesDifficulty;
        });

        // Add subcategory if there are any matching lessons
        if (filteredLessons.length > 0) {
          subAcc[subCategory] = { ...lessonsData, lessons: filteredLessons };
        }
        return subAcc;
      }, {});

      // Add category if there are any matching subcategories
      if (Object.keys(filteredSubCategories).length > 0) {
        acc[category] = filteredSubCategories;
      }

      return acc;
    }, {});
  };



    // Update the displayed activities whenever the search term changes
  const displayedActivities = searchTerm || difficulty !== 'All'
    ? filterActivitiesBySearchTerm(userActivities, searchTerm, difficulty)
    : userActivities;


  const toggleProfiles = () => {
    setShowProfiles(prevShowProfiles => !prevShowProfiles);
    console.log(`Profiles visibility toggled to: ${!showProfiles}`);
  };

  // After fetching the children profiles
  console.log(childrenProfiles);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="user-profile-page">
      {showLogoutModal && <LogoutModal />}
      <div className="topic-header-user">
          <img src={logoImage} alt="iLUMINA Logo" className="topic-logo" onClick={goToSubject} />
          <div className="header-right-section">
            {userRole === 'superuser' && isFamilyPlan && (
              <>
                {childrenProfiles.length < 4 && (
                  <div className={`dropdown ${showModal ? 'open' : ''}`}>
                    <i className="fas fa-plus icon" onClick={() => {
                      setShowModal(!showModal)
                      setShowProfiles(false);
                    }}></i> {/* Plus Icon */}
                    {showModal && (
                      <div className="dropdown-content modal-content">
                        <form onSubmit={handleAddChild}>
                          <input
                            type="text"
                            placeholder="Child Username"
                            value={childUsername}
                            onChange={e => setChildUsername(e.target.value)}
                          />
                          <input
                            type="password"
                            placeholder="Child Password"
                            value={childPassword}
                            onChange={e => setChildPassword(e.target.value)}
                          />
                          <button type="submit">Add Child Profile</button>
                        </form>
                      </div>
                    )}
                  </div>
                )}
                <div className={`dropdown ${showProfiles ? 'open' : ''}`}>
                  <i className="fas fa-sync-alt icon" onClick={() => {
                    setShowProfiles(!showProfiles)
                    setShowModal(false);
                  }}></i> {/* Sync Icon */}
                  {showProfiles && (
                    <div className="dropdown-content children-profiles">
                      <ul>
                        {childrenProfiles.map(child => (
                          <li key={child.id} onClick={() => switchProfile(child.id)}>
                            {child.username}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <UserIcon className="user-profile-icon" style={{ fill: '#517d90' }} />
      </div>
      <h1 className="welcome-message">Welcome {userName}!</h1>
      <div className="summary-stats">
        <p>Total Lessons: {totalLessons}</p>
        <p>Total Unique Lessons: {uniqueLessons}</p>
      </div>
      <input
        type="text"
        placeholder="Search for lessons..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-bar"
      />
      <select onChange={handleDifficultyChange} value={difficulty} className="dropdown-menu">
        <option value="All">All</option>
        <option value="Beginner">Beginner</option>
        <option value="Intermediate">Intermediate</option>
        <option value="Advanced">Advanced</option>
        <option value="Expert">Expert</option>
      </select>
      <h1 className="assessment-text">Completed Assessments</h1>
      <div className="user-activities">
        {Object.entries(displayedActivities).map(([category, subCategories]) => (
          <div key={category} className="activity-category">
            <h2 onClick={() => toggleOpen(category)}>{category}</h2>
            {Object.entries(subCategories).map(([subCategory, data]) => (
              <ul>{renderLessons(category, subCategory)}</ul>
            ))}
          </div>
        ))}
      </div>
      <div className="button-container">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
        <button className="exit-button" onClick={() => navigate('/subject-selection')}>Exit</button>
      </div>
    </div>
  );

}

export default UserPage;
