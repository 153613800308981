import React from 'react';
import './AboutPage.css'; // Ensure CSS is correctly linked
import background from '../../assets/about_image.png';
import logoImage from '../../assets/ilumina.png'; // Ensure this is the correct path
import { useNavigate } from 'react-router-dom';

function AboutPage() {
    const navigate = useNavigate();
    const goToLanding = () => {
        navigate('/');
    };

    const goBack = () => {
        navigate(-1); // This will take the user back to the previous page
    };

    return (
        <div className="about-page" style={{ backgroundImage: `url(${background})` }}>
            <header className="about-header">
                <h1>About Us</h1>
                <img src={logoImage} alt="iLUMINA Logo" className="about-logo" onClick={goToLanding}/>
            </header>
            <div className="about-content">
                <p>Welcome to ilumina, where innovation in education begins. Launching in 2024, our mission is to make learning accessible and personalized for everyone, everywhere.</p>
                <p>At iLumina, we harness the latest advances in artificial intelligence to provide tailored educational experiences that adapt to the individual needs of each learner. This approach allows us to create a learning environment where everyone can thrive and reach their highest potential.</p>
                <div className="mission-statement">
                    <h2>Our Mission</h2>
                    <p>To democratize education, making it a personal journey that anyone can embark on, no matter who or where they are. Our AI-driven platform offers unique, personalized learning experiences that empower students and help them achieve their full potential.</p>
                </div>
                <div className="vision-statement">
                    <h2>Looking Ahead</h2>
                    <p>As we launch our web application, our vision is to expand globally, reaching students in all corners of the world. We are driven by the belief that everyone deserves access to quality education that is designed for them, and we are excited to lead the charge in making this a reality.</p>
                </div>
                <div className="ai-content-section">
                    <h2>Our Use of AI-Generated Content</h2>
                    <p>At ilumina, we utilize advanced Artificial Intelligence (AI) technology to generate all our lesson content and assessments. This approach ensures personalized learning, consistency and scalability, and dynamic content updates.</p>
                    <p><strong>Personalized Learning:</strong> AI takes into account the learning level of each individual to tailor educational content that meets their unique needs.</p>
                    <p><strong>Consistency and Scalability:</strong> AI enables us to maintain high standards of quality and consistency, allowing us to efficiently scale our offerings. Where some educational sites are limited to a single subject, we are able to provide limitless offerings. Let us know what you would like to see next!</p>
                    <p><strong>Dynamic Content:</strong> AI's capability to update content ensures that our lessons remain relevant and up-to-date.</p>
                    <p><strong>Feedback and Continuous Improvement:</strong> We encourage feedback on our AI-generated content, which helps us refine and improve our offerings. If you have suggestions or comments, please let us know through our help/feedback portal.</p>
                </div>
                <p>Join us on this journey as we seek to reshape the educational landscape and create a learning environment where everyone, everywhere, can become their best selves.</p>
            </div>
            <footer className="footer">
                <button onClick={goBack} className="back-button">Go Back</button> {/* Style this button as needed */}
            </footer>
        </div>
    );
}

export default AboutPage;


