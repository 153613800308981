import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HistoryPage.css';
import backgroundImg from '../../assets/history_background.png'; // Replace with the path to your background image
import UserIcon from '../shared/UserIcon'; // Import the UserIcon component
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png'; // Replace with the path to your logo image
import axios from 'axios';

// A sample data structure for lessons, replace with actual data
export const history_lessons = {
  "Ancient Civilizations": {
    subcategories: {
      "Mesopotamia": [
        "The Cradle of Civilization",
        "Sumer and the First Cities",
        "Akkadian Empire and Sargon the Great",
        "Babylonian Society and Hammurabi's Code",
        "Assyrian Empire and Military Dominance",
        "The Neo-Babylonian Empire and the Hanging Gardens",
        "Mesopotamian Mythology and Religion",
        "Cuneiform Writing and Mesopotamian Culture"
      ],
      "Ancient Egypt": [
        "Understanding the Nile River",
        "Early Dynastic Period of Egypt",
        "The Great Pyramids and the Sphinx of Giza",
        "Middle Kingdom: Reunification and Stability",
        "New Kingdom: The Age of Empire",
        "Religion and Gods of Ancient Egypt",
        "Hieroglyphics and Ancient Egyptian Art",
        "The Decline of Ancient Egypt and the Ptolemaic Dynasty"
      ],
      "Indus Valley Civilization": [
        "Discovery of Harappa and Mohenjo-Daro",
        "City Planning and Architecture",
        "Agriculture and Trade in the Indus Valley",
        "The Undeciphered Script of the Indus People",
        "Daily Life and Social Structure",
        "Religious Beliefs and Practices",
        "End of the Indus Valley Civilization: Theories",
        "Legacy of the Indus Valley Civilization"
      ],
      "Ancient China": [
        "Yellow River Civilization: The Dawn of Chinese History",
        "Shang Dynasty: Oracle Bones and Bronze Age",
        "Zhou Dynasty and the Mandate of Heaven",
        "Warring States and the Development of Confucianism",
        "Qin Dynasty and the Unification of China",
        "Han Dynasty: Expansion and Silk Road",
        "Philosophies: Confucianism, Daoism, and Legalism",
        "Great Wall of China and Chinese Inventions"
      ],
      "Ancient Greece": [
        "Minoans and Mycenaeans: Precursors to Hellenic Civilization",
        "Polis: The Greek City-State",
        "Sparta vs. Athens: Contrasting Societies",
        "Greek Philosophy: Socrates, Plato, and Aristotle",
        "The Persian Wars and the Rise of Athens",
        "Golden Age of Athens and the Peloponnesian War",
        "Alexander the Great and the Hellenistic Age",
        "Olympics and Greek Mythology"
      ],
      "Ancient Rome": [
        "Foundation Myths and the Roman Kingdom",
        "The Roman Republic and Roman Constitution",
        "Punic Wars and Roman Expansion",
        "The Transition from Republic to Empire",
        "Pax Romana and the Five Good Emperors",
        "Roman Law and Engineering",
        "Daily Life in Ancient Rome",
        "The Fall of the Western Roman Empire"
      ]
    }
  },
  "Medieval History": {
    subcategories: {
      "Byzantine Empire": [
        "From Eastern Roman to Byzantine: The Transformation",
        "Justinian and Theodora: A Golden Age",
        "Byzantine Art and Architecture: Hagia Sophia",
        "Greek Fire and Military Might",
        "Iconoclasm: A Religious Controversy",
        "The Macedonian Renaissance in Byzantium",
        "The Great Schism: Division of East and West",
        "The Fall of Constantinople and the End of Byzantium"
      ],
      "Islamic Caliphates": [
        "The Life of Prophet Muhammad and the Rise of Islam",
        "The Rightly Guided Caliphs and Expansion",
        "Umayyad Caliphate and Islamic Golden Age",
        "Abbasid Caliphate: Learning and Innovations",
        "Fatimids, Seljuks, and Crusader States",
        "The Spread of Islam in Asia and Africa",
        "Cultural Contributions: Science, Medicine, and Literature",
        "The Mongol Invasion and the Decline of the Caliphates"
      ],
      "Viking Age": [
        "Who Were the Vikings? Origins and Culture",
        "Viking Raids and Settlements in Europe",
        "Viking Ships and Navigation Techniques",
        "Viking Society: Life and Social Structure",
        "Religion and Mythology of the Norse People",
        "Famous Vikings and Their Sagas",
        "Viking Trade and Exploration Beyond Europe",
        "The Christianization and Decline of the Viking Age"
      ],
      "Feudalism in Europe": [
        "Origins and Structure of Feudal Society",
        "Life on a Manor: Lords, Vassals, and Serfs",
        "Knights and Chivalry: The Military Elite",
        "Medieval Castles: Architecture and Defense",
        "The Medieval Church and Its Influence on Society",
        "Agricultural Innovations and the Medieval Economy",
        "The Rise of Towns and the Middle Class",
        "Decline of Feudalism: Black Death and Hundred Years' War"
      ],
      "Crusades": [
        "The Call for Crusade: Causes and Motivations",
        "First Crusade: A New Holy War",
        "Crusader States and the Knights Templar",
        "Saladin and the Muslim Response",
        "The Fourth Crusade and the Sack of Constantinople",
        "Later Crusades and Their Impact",
        "The Children's Crusade: Myth and Reality",
        "Legacy of the Crusades: Consequences and Reflections"
      ],
      "Mongol Empire": [
        "Genghis Khan: Unification of the Mongol Tribes",
        "Conquests: Building the Largest Land Empire",
        "Mongol Rule: Administration and Tolerance",
        "Kublai Khan and the Yuan Dynasty in China",
        "Mongols in Russia: The Golden Horde",
        "The Ilkhanate: Mongols in the Middle East",
        "The End of the Empire: Fragmentation and Legacy",
        "The Silk Road under Mongol Control: Trade and Exchange"
      ]
    }
  },
  "Renaissance and Exploration": {
    subcategories: {
      "Italian Renaissance": [
        "Origins of the Italian Renaissance",
        "Humanism: Rediscovery of Classical Philosophy",
        "Renaissance Art: Perspective, Symmetry, and Beauty",
        "Leonardo da Vinci and the Ideal Renaissance Man",
        "Renaissance Literature: Dante and Petrarch",
        "The Politics of the Italian City-States",
        "Michelangelo's Legacy in Sculpture and Painting",
        "The End of the Renaissance in Italy"
      ],
      "Northern Renaissance": [
        "The Spread of Renaissance to Northern Europe",
        "Alchemy, Science, and Technology in the North",
        "Northern Renaissance Art: Van Eyck and Dürer",
        "Erasmus: The Prince of the Humanists",
        "Thomas More's Utopia and Social Criticism",
        "Shakespeare and the Elizabethan Era",
        "The Printing Press: Gutenberg and the Spread of Knowledge",
        "Differences Between the Italian and Northern Renaissance"
      ],
      "Age of Discovery": [
        "The Navigational Advances Leading to Exploration",
        "Prince Henry the Navigator and the Portuguese Explorations",
        "Columbus's Voyage: Finding the New World",
        "The Treaty of Tordesillas: Dividing the World",
        "Magellan's Circumnavigation and the Concept of a Globe",
        "The Conquistadors: Cortes and the Aztecs, Pizarro and the Incas",
        "The Columbian Exchange: Goods and Disease",
        "Impact of the Age of Discovery on Europe and the World"
      ],
      "Ottoman Expansion": [
        "Rise of the Ottoman Turks and Fall of Constantinople",
        "Suleiman the Magnificent and the Peak of the Empire",
        "Ottoman Military Organization and Janissaries",
        "Ottoman Art and Architecture: The Mosque of Hagia Sophia",
        "The Ottomans and the Silk Road",
        "Naval Battles: Controlling the Mediterranean",
        "The Siege of Vienna and the Limits of Expansion",
        "Cultural Legacy and Decline of the Ottoman Empire"
      ],
      "Reformation": [
        "Early Calls for Reform within the Church",
        "Martin Luther and the 95 Theses",
        "Spread of Protestantism: Calvin, Zwingli, and Beyond",
        "The English Reformation: Henry VIII and Anglicanism",
        "The Catholic Counter-Reformation and Council of Trent",
        "The Wars of Religion: Europe's Battlegrounds",
        "The Peace of Westphalia and Religious Tolerance",
        "Long-term Effects of the Reformation on Europe"
      ]
    }
  },
  "Early Modern Period": {
    subcategories: {
      "Scientific Revolution": [
        "The Dawn of Modern Science: Context and Background",
        "Copernicus and the Heliocentric Theory",
        "Kepler and the Laws of Planetary Motion",
        "Galileo: Telescopes, Discoveries, and Controversies",
        "Isaac Newton and the Universal Laws of Gravitation",
        "The Impact of Newtonian Physics",
        "Scientific Institutions and Societies",
        "Legacy and Impact of the Scientific Revolution"
      ],
      "Enlightenment": [
        "The Age of Reason: Introduction to the Enlightenment",
        "John Locke and Theories of Government and Liberty",
        "Philosophes and Their Ideas for a Better Society",
        "Voltaire and the Fight against Prejudice and Superstition",
        "Rousseau and the Concept of the Social Contract",
        "The Encyclopédie: Diderot and the Democratization of Knowledge",
        "Enlightened Despotism in Europe",
        "The Influence of the Enlightenment on the American and French Revolutions"
      ],
      "Absolutism in Europe": [
        "The Divine Right of Kings: Foundation of Absolutism",
        "Louis XIV and Versailles: The Sun King's Court",
        "Peter the Great and the Westernization of Russia",
        "The War of Spanish Succession and the Balance of Power",
        "Prussia's Rise under Frederick the Great",
        "Absolutism in Spain and the Decline of the Habsburgs",
        "The Partition of Poland and the Limits of Absolutism",
        "The Enlightenment's Critique of Absolutism"
      ],
      "Thirty Years' War": [
        "The Bohemian Revolt and the Defenestration of Prague",
        "The Danish Phase: Protestant Setbacks",
        "The Swedish Intervention and Gustavus Adolphus",
        "The French Phase and the Rise of Richelieu's Diplomacy",
        "The Peace of Westphalia and the End of the War",
        "Social and Economic Impact of the War",
        "The Political Reordering of Europe after Westphalia",
        "Memory and Legacy of the Thirty Years' War in European Culture"
      ],
      "Colonial America": [
        "Early European Settlements and Native American Relations",
        "The Puritan Ethos and the Massachusetts Bay Colony",
        "Colonial Government and Democracy in the Virginia House of Burgesses",
        "Life in the Middle Colonies: Trade, Diversity, and Tolerance",
        "The Great Awakening and Religious Movements",
        "The French and Indian War and Colonial Unity",
        "Colonial Economies: Agriculture, Trade, and Slavery",
        "Road to Revolution: Acts, Taxes, and Colonial Response"
      ]
    }
  },
  "Revolutionary Period": {
    subcategories: {
      "American Revolution": [
        "Colonial Discontent and the Seeds of Revolution",
        "The Boston Tea Party and the Coercive Acts",
        "The First Continental Congress and Colonial Unity",
        "The Outbreak of War: Lexington and Concord",
        "Declaration of Independence and Enlightenment Ideals",
        "Key Battles: Saratoga and Yorktown",
        "French Alliance and the Role of Foreign Aid",
        "The Treaty of Paris and the Birth of a Nation"
      ],
      "French Revolution": [
        "The Ancien Régime and the Estates-General",
        "The Storming of the Bastille and the Great Fear",
        "The Declaration of the Rights of Man and of the Citizen",
        "The Reign of Terror and the Rise of Robespierre",
        "The Thermidorian Reaction and the Directory",
        "Napoleon’s Coup and the Consulate",
        "The Napoleonic Code and Reforms",
        "The Legacy of the French Revolution"
      ],
      "Latin American Wars of Independence": [
        "Enlightenment Ideas and Creole Discontent",
        "Simón Bolívar and the Liberation of Northern South America",
        "José de San Martín and the Southern Campaigns",
        "The Mexican War of Independence and Father Hidalgo",
        "The Role of Brazil in Latin American Independence",
        "The Impact of Napoleon’s Invasion of Spain",
        "Post-Independence Challenges and the Search for Stability",
        "Cultural and Social Changes Post-Independence"
      ],
      "Industrial Revolution": [
        "The Agricultural Revolution and Pre-Industrial Society",
        "Technological Innovations and the Factory System",
        "The Steam Engine and Transportation: Railroads and Canals",
        "Urbanization and the Growth of Cities",
        "The Social and Economic Impacts on the Working Class",
        "Child Labor, Reform Movements, and the Factory Acts",
        "Capitalism and Its Role in Innovation and Economic Expansion",
        "The Rise of the Middle Class and Consumer Culture",
        "Global Impact: Trade, Empire, and the Spread of Industrialization"
      ]
    }
  },
  "19th Century": {
    subcategories: {
      "Napoleonic Wars": [
        "The Rise of Napoleon Bonaparte",
        "Napoleonic Reforms and the French Empire",
        "Major Battles: Austerlitz, Trafalgar, and Borodino",
        "The Continental System and its Effects",
        "The Invasion of Russia and the Beginning of the End",
        "The Fall of Napoleon: Leipzig and Waterloo",
        "The Congress of Vienna and the Restoration of Europe",
        "Napoleon's Legacy and the 19th Century"
      ],
      "Victorian Era": [
        "Queen Victoria's Reign: An Overview",
        "Industrial Expansion and Technological Innovation",
        "The British Empire: Colonization and Global Influence",
        "Social Class, Victorian Values, and Etiquette",
        "Literature and Culture of the Victorian Period",
        "Social Reforms and Movements",
        "Scientific Discoveries and the Questioning of Faith",
        "The End of an Era: Preparing for the 20th Century"
      ],
      "American Civil War": [
        "The Antebellum South and the Rise of Abolitionism",
        "Secession and the Confederate States of America",
        "First Shots: Fort Sumter and the War's Outbreak",
        "Key Battles: Gettysburg, Antietam, and Vicksburg",
        "Emancipation Proclamation and African American Soldiers",
        "The Home Front: Women, Industry, and Agriculture",
        "Sherman's March and Total War",
        "The War's End: Surrender at Appomattox and Reconstruction"
      ],
      "Unification of Italy and Germany": [
        "The Italian Unification: Cavour, Mazzini, and Garibaldi",
        "The Role of the Kingdom of Sardinia and the Risorgimento",
        "The German Unification: Bismarck and Realpolitik",
        "The Wars of Unification: Denmark, Austria, and France",
        "The Proclamation of the Italian Kingdom and the German Empire",
        "The Impact of Unification on Europe's Power Structure",
        "Cultural and Economic Effects of Unification",
        "The Legacy of Unification in World Politics"
      ],
      "Meiji Restoration": [
        "The Tokugawa Shogunate and the Opening of Japan",
        "The Meiji Emperor and the Restoration of Imperial Rule",
        "Modernization and Westernization Efforts",
        "Socio-political Reforms and the Abolition of the Samurai",
        "Industrialization and the Rise of a New Economy",
        "Cultural Changes: Education, Dress, and Etiquette",
        "Japan's Emergence as a World Power",
        "Evaluating the Meiji Restoration's Success and Failures"
      ],
    }
  },
  "World Wars": {
    subcategories: {
      "World War I": [
        "The Alliances and the Arms Race",
        "The Assassination of Archduke Franz Ferdinand",
        "Trench Warfare and the Western Front",
        "The Eastern Front and the Global Impact",
        "Major Battles and Military Strategies",
        "The Home Front: Total War and Propaganda",
        "The United States' Entry and the War's Turning Point",
        "The Treaty of Versailles and the League of Nations"
      ],
      "Interwar Period": [
        "The Roaring Twenties and Economic Boom",
        "The Great Depression and Global Economic Collapse",
        "Rise of Totalitarianism: Stalin, Mussolini, and Hitler",
        "Art and Culture in the Shadow of War",
        "The Road to War: Aggression and Appeasement",
        "Technological and Military Innovations",
        "Society and Politics: Women's Rights and Civil Movements",
        "The Prelude to a Second World War"
      ],
      "World War II": [
        "The Blitzkrieg and the Fall of France",
        "The Battle of Britain and the Air War",
        "Barbarossa: The Invasion of the Soviet Union",
        "The Pacific Theater: Pearl Harbor to Midway",
        "The Turning Tide: Stalingrad and the Allied Invasion",
        "The Holocaust: Persecution and Genocide",
        "The Atomic Bomb: Hiroshima and Nagasaki",
        "The Post-War World: UN and the Cold War Beginnings"
      ],
      "Holocaust": [
        "Anti-Semitism and the Nazi Ideology",
        "Ghettos and the Final Solution",
        "Concentration and Death Camps",
        "Resistance and Resilience: Stories of Survival",
        "Liberation and the Aftermath of the Holocaust",
        "Legal Reckoning: The Nuremberg Trials",
        "The Holocaust in Memory and Education",
        "Preventing Future Genocide: Lessons Learned"
      ],
      "Cold War": [
        "The Iron Curtain and the Division of Europe",
        "The Marshall Plan and the Berlin Airlift",
        "NATO and the Warsaw Pact: Military Standoff",
        "The Korean War and the Concept of Containment",
        "The Cuban Missile Crisis: Brinkmanship",
        "Détente and the Space Race",
        "The Vietnam War and its Global Impact",
        "The Fall of the Soviet Union and the End of the Cold War"
      ],
    }
  },
  "Contemporary History": {
    subcategories: {
      "Space Race": [
        "Sputnik to Apollo: The Race to the Moon",
        "Satellites and the Global Communication Revolution",
        "Human Spaceflight: Achievements and Tragedies",
        "The Shuttle Era and the Hubble Telescope",
        "The International Space Station and Cooperation",
        "Mars Rovers and the Search for Extraterrestrial Life",
        "Privatization of Space: Entrepreneurs and Exploration",
        "The Future of Space Travel: Colonization and Beyond"
      ],
      "Digital Revolution": [
        "The Invention of the Microchip and Personal Computers",
        "The Internet: From Arpanet to the World Wide Web",
        "Mobile Technology and the Smartphone Explosion",
        "Social Media: Connectivity and Impact on Society",
        "Big Data, AI, and the Ethical Dilemmas",
        "Cybersecurity and the New Warfare",
        "E-Commerce and the Changing Global Economy",
        "Tech Giants: Innovation and Monopolies"
      ],
      "War on Terror": [
        "9/11 and the New Global Threat",
        "Afghanistan: The Endless War",
        "Iraq: WMDs and the Fall of Saddam",
        "Counterterrorism Efforts and Homeland Security",
        "ISIS: Origins and the Fight Against Extremism",
        "Surveillance, Rights, and Civil Liberties",
        "The Impact of the War on Terror on the Middle East",
        "The Ongoing Challenge of Global Terrorism"
      ],
    }
  },
  "Cultural History": {
    subcategories: {
      "History of Religion": [
        "Introduction to World Religions: Understanding Diversity and Commonalities",
        "The Birth and Spread of Hinduism: Ancient Rituals and Modern Practices",
        "Buddhism: From Siddhartha Gautama to Global Philosophy",
        "Judaism: Covenant, Tradition, and Influence in the Modern World",
        "Christianity: The Life of Jesus and the Growth of the Church",
        "Islam: The Life of Prophet Muhammad and the Spread of Islamic Teachings",
        "Rich Traditions and Beliefs Around the World",
        "Religious Charity: How Faith Motivates Altruism and Community Service",
        "Interfaith Understanding: Building Bridges Through Dialogue and Respect",
      ],
      "History of Science and Technology": [
        "The Scientific Revolution and its Heroes",
        "The Industrial Revolution and Technological Change",
        "The Information Age and Computer Science",
        "Biotechnology and the Human Genome Project",
        "Nanotechnology and Material Sciences",
        "Renewable Energy and the Green Revolution",
        "Space Exploration and New Frontiers",
        "Ethics of Science and the Responsibility of Scientists"
      ],
      "History of Art": [
        "Prehistoric Art and the Dawn of Creativity",
        "Classical Art: Greek and Roman Contributions",
        "The Renaissance and Humanistic Expression",
        "Baroque to Romanticism: Emotion and Grandeur",
        "Impressionism to Modernism: Breaking the Rules",
        "Contemporary Art: Concepts and Movements",
        "Art Criticism and the Philosophy of Beauty",
        "Digital Art and the Evolution of New Media"
      ],
      "History of Music": [
        "Ancient Music and Early Instruments",
        "Baroque and Classical Eras: Forms and Genres",
        "Romanticism: Emotion and Virtuosity",
        "Early 20th Century: Experimentation and Jazz",
        "The Rise of Popular Music and Rock 'n' Roll",
        "Music Technology: Recording and Synthesis",
        "World Music and Cross-Cultural Influences",
        "The Digital Age: Streaming and the Music Industry"
      ],
      "History of Philosophy": [
        "Pre-Socratic Philosophers and the Search for Knowledge",
        "Classical Philosophy: Plato and Aristotle",
        "Medieval Philosophy and Theological Integration",
        "Renaissance Humanism and the Birth of Modern Thought",
        "Enlightenment Philosophers and Theories of Society",
        "19th-Century Philosophy: Existentialism and Pragmatism",
        "20th-Century Schools: Phenomenology, Structuralism, Post-Structuralism",
        "Contemporary Philosophical Trends and the Information Era"
      ],
    }
  },
};

function HistoryPage() {
  const showLogoutModal = useInactivityTimer()
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const difficultyLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const onMainCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
    setSelectedSubcategory(null); // Reset subcategory selection when a different main category is clicked
  };

  const onSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(selectedSubcategory === subcategory ? null : subcategory);
  };

  const onSubSubcategoryClick = (subSubcategoryName, mainCategoryName, subCategoryName) => {
    // Check if the currently selected lesson matches the clicked item and toggle off if so
    if (selectedLesson && selectedLesson.subSubcategoryName === subSubcategoryName) {
      setSelectedLesson(null);
    } else {
      setSelectedLesson({
        subSubcategoryName,
        mainCategoryName,
        subCategoryName
      });
    }
  };

  const onDifficultyClick = async (difficulty) => {
    const { subSubcategoryName, mainCategoryName, subCategoryName } = selectedLesson;
    try {
      const userId = localStorage.getItem('userID');
      const response = await axios.post(`${apiBaseUrl}/users/${userId}/log-activity`, {
        lesson_name: `${subCategoryName}: ${subSubcategoryName}`,
        subject: 'History',
        category: mainCategoryName,
        difficulty: difficulty
      }, {
        withCredentials: true
      });
      console.log('Log activity response:', response.data.lesson_id);
      const lessonId = response.data.lesson_id;
      navigate(`/lesson/${subSubcategoryName}`, { state: {
        subjectName: 'History',
        categoryName: mainCategoryName,
        subCategoryName: subCategoryName,
        difficulty: difficulty,
        lessonId: lessonId
      }});
      setSelectedLesson(null); // Reset lesson selection after navigating
    } catch (error) {
      console.error('Error logging user activity', error);
    }
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="history-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
      {showLogoutModal && <LogoutModal />}
      <div className="topic-header">
        <img src={logoImage} alt="iLUMINA Logo" className="topic-logo" onClick={goToSubject} />
        <UserIcon className="user-profile" style={{ fill:'#517d90' }} onClick={goToUserPage} />
      </div>
      <div className="history-container">
        {Object.entries(history_lessons).map(([mainCategory, data]) => (
          <div className="history-category" key={mainCategory}>
            <h2 onClick={() => onMainCategoryClick(mainCategory)}>{mainCategory}</h2>
            <ul>
              {Object.entries(data.subcategories).map(([subcategory, subItems]) => (
                <li key={subcategory} onClick={(e) => {
                  e.stopPropagation(); // Stop propagation to prevent mainCategory click
                  onSubcategoryClick(subcategory);
                }} className={selectedSubcategory === subcategory ? 'subcategory open' : 'subcategory'}>
                  {subcategory}
                  <ul>
                    {subItems.map(subItem => (
                      <li key={subItem} onClick={(e) => {
                        e.stopPropagation();  // Prevent the subcategory's click event
                        onSubSubcategoryClick(subItem, mainCategory, subcategory);
                      }}>
                        {subItem}
                        {selectedLesson && selectedLesson.subSubcategoryName === subItem && (
                          <div className="difficulty-selector">
                            {difficultyLevels.map(level => (
                              <button key={level} onClick={(e) => {
                                e.stopPropagation(); // Prevent the li click event
                                onDifficultyClick(level);
                              }}>
                                {level}
                              </button>
                            ))}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="back-button" onClick={goToSubject}>Back to Subjects</button>
    </div>
  );

}

export default HistoryPage;
