import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from '../LandingPage/LandingPage';
import AboutPage from '../AboutUsPage/AboutPage'
//import LandingPageAlt from '../LandingPage/LandingPageAlt'
import PasswordReset from '../PasswordReset/PasswordReset';
import SignUpPage from '../SignUpPage/SignUpPage';
import PaymentPage from '../PaymentPage/PaymentPage';
import PaymentOptionPage from "../PaymentOptionPage/PaymentOptionPage";
import SubjectSelectionPage from '../SubjectSelectionPage/SubjectSelectionPage';
import MathPage from '../LessonSelectionPages/MathPage';
import HistoryPage from '../LessonSelectionPages/HistoryPage';
import LitPage from '../LessonSelectionPages/LitPage';
import SciencePage from '../LessonSelectionPages/SciencePage';
import LessonPage from '../LessonPage/LessonPage';
import UserPage from '../UserPage/UserPage';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import HelpPage from '../HelpPage/HelpPage'
import CheckoutProcessingPage from "../CheckoutProcessingPage/CheckoutProcessingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/select-plan" element={<PaymentOptionPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/return" element={<CheckoutProcessingPage />} />
        <Route path="/subject-selection" element={<ProtectedRoute><SubjectSelectionPage /></ProtectedRoute>} />
        <Route path="/math" element={<ProtectedRoute><MathPage /></ProtectedRoute>} />
        <Route path="/history" element={<ProtectedRoute><HistoryPage /></ProtectedRoute>} />
        <Route path="/literature" element={<ProtectedRoute><LitPage /></ProtectedRoute>} />
        <Route path="/science" element={<ProtectedRoute><SciencePage /></ProtectedRoute>} />
        <Route path="/lesson/:lessonName" element={<ProtectedRoute><LessonPage /></ProtectedRoute>} />
        <Route path="/user-profile" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
        {/* ... other routes ... */}
      </Routes>
    </Router>
  );
}

export default App;







