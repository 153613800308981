import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../SignUpPage/SignUpPage.css'; // Using the same CSS for styling
import axios from 'axios';

function PasswordResetPage() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const isValidEmail = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const isValidPassword = (password) => {
    // This regex checks for at least one uppercase letter, one lowercase letter, one number, and any character that is not a letter or digit (considered as a special character).
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!passwordError && !confirmPasswordError && !emailError) {
      if (newPassword !== confirmNewPassword) {
        setConfirmPasswordError("Passwords don't match.");
        return;
      }

      try {
        // Replace URL with your actual password reset endpoint
        const response = await axios.post(`${apiBaseUrl}/users/reset-password`, { email, newPassword });
        if (response.status === 200) {
          alert('Password reset successfully!');
          navigate('/');  // Adjust as needed
        }
      } catch (error) {
        alert('An error occurred during password reset. Please try again.');
      }
    }
  };

    const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  return (
    <div className="signup-page">
      <form onSubmit={handlePasswordReset}>
        <h1>Reset Password</h1>
        <p>Please enter your email and new password.</p>
        <div>
          <input type="email" placeholder="Email" value={email} onChange={(e) => {
            setEmail(e.target.value);
            isValidEmail(e.target.value);
          }} />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <div className="input-container">
          <input
            type={showNewPassword ? 'text' : 'password'}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              isValidPassword(e.target.value);
            }}
            className="password-input"
          />
          <i onClick={toggleNewPasswordVisibility} className={`fas ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}></i>
          {passwordError && <div className="error-message">{passwordError}</div>}
        </div>
        <div className="input-container">
          <input
            type={showConfirmNewPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
              setConfirmPasswordError(e.target.value !== newPassword ? "Passwords don't match." : '');
            }}
            className="password-input"
          />
          <i onClick={toggleConfirmPasswordVisibility} className={`fas ${showConfirmNewPassword ? 'fa-eye-slash' : 'fa-eye'} eye-icon`}></i>
          {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
        </div>
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
}

export default PasswordResetPage;
