import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './LitPage.css';
import backgroundImg from '../../assets/literature_background.png'; // Replace with the path to your background image
import UserIcon from '../shared/UserIcon'; // Import the UserIcon component
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png';
import axios from "axios"; // Replace with the path to your logo image


// A sample data structure for lessons, replace with actual data
export const lit_lessons = {
  "Genres": {
    subcategories: {
      "Fiction": [
        "Introduction to Fiction: Elements & Structure",
        "Character Development in Fiction",
        "Plot and Conflict",
        "Themes and Symbols",
        "Advanced Fiction Writing Techniques"
      ],
      "Non-Fiction": [
        "Exploring Non-Fiction: Types and Characteristics",
        "Biographies and Autobiographies",
        "Journalism in Non-Fiction",
        "Narrative Non-Fiction",
        "Crafting Persuasive Essays and Articles"
      ],
      "Poetry": [
        "Poetry Basics: Form, Meter, and Rhyme",
        "Imagery and Figurative Language in Poetry",
        "Voice and Style in Poetry",
        "Modern Poetry and Free Verse",
        "Analyzing Poetic Works"
      ],
      "Drama": [
        "Introduction to Drama and Playwriting",
        "Structure of a Play: Acts and Scenes",
        "Character Arcs in Drama",
        "Dialogue and Monologue",
        "Stage Direction and Performance"
      ],
      "Science Fiction": [
        "Defining Science Fiction: Key Themes and Concepts",
        "Building Sci-Fi Worlds",
        "Science and Plausibility in Sci-Fi",
        "Character Types in Science Fiction",
        "Dystopia and Utopia in Sci-Fi Narratives"
      ],
      "Fantasy": [
        "Fantasy Worlds and How to Build Them",
        "Magic Systems and Their Rules",
        "Mythological Inspirations in Fantasy",
        "Character Archetypes in Fantasy",
        "Epic Quests and Adventures"
      ],
      "Mystery": [
        "Understanding the Mystery Genre",
        "Creating Suspense and Tension",
        "Developing a Detective Character",
        "Crafting the Perfect Whodunit",
        "The Art of Misdirection and Red Herrings"
      ],
      "Historical Fiction": [
        "Historical Research for Writers",
        "Setting and Authenticity",
        "Famous Historical Settings in Literature",
        "Blending Fact with Fiction",
        "Historical Fiction Sub-genres"
      ],
    }
  },
  "Literary Periods": {
    subcategories: {
      "Classical": [
        "Introduction to Classical Literature",
        "Homer's Epics: The Iliad and The Odyssey",
        "Greek Tragedy and the Works of Sophocles",
        "Roman Literary Contributions: Virgil's Aeneid",
        "Classical Philosophers: Plato and Aristotle",
        "Classical Influence on Later Literature"
      ],
      "Medieval": [
        "Understanding Medieval Literature",
        "The Chanson de Roland and Epic Poetry",
        "Courtly Love and Arthurian Legends",
        "Dante's Divine Comedy: Structure and Themes",
        "Chaucer and The Canterbury Tales",
        "Medieval Morality Plays and Allegories"
      ],
      "Renaissance": [
        "Defining the Renaissance in Literature",
        "Humanism and Its Literary Impact",
        "Shakespeare's Plays and Poetic Works",
        "Pastoral and Lyric Poetry",
        "Renaissance Prose: Essays and Fiction",
        "Cross-Cultural Influences and the Renaissance"
      ],
      "Enlightenment": [
        "The Age of Reason in Literature",
        "Satire and Wit: Swift and Voltaire",
        "Rise of the Novel: Defoe and Richardson",
        "Enlightenment Philosophy in Literature",
        "Transitions to Romanticism",
        "The Legacy of Enlightenment Ideas"
      ],
      "Romanticism": [
        "Romanticism: Nature, Emotion, and Imagination",
        "Poetic Revolution: Wordsworth and Coleridge",
        "Gothic and Romantic Fiction",
        "The Byronic Hero and Lord Byron's Works",
        "American Romanticism: Poe and Hawthorne",
        "Women Writers of the Romantic Period"
      ],
      "Victorian": [
        "Victorian Values and Literary Forms",
        "The Victorian Novel: Dickens and Thackeray",
        "Poetry in the Victorian Age",
        "Industrialization and Social Issues in Literature",
        "The Brontë Sisters and the Female Voice",
        "Decadence and Aestheticism as a Prelude to Modernism"
      ],
      "Modernism": [
        "Modernism and the Break with Tradition",
        "Stream of Consciousness Technique",
        "T.S. Eliot and Modernist Poetry",
        "The Lost Generation and Expatriate Writers",
        "Dystopian Themes and Early 20th Century Unrest",
        "Modernism in the Global Context"
      ],
      "Postmodernism": [
        "Characteristics of Postmodern Literature",
        "Metafiction and Self-Referential Works",
        "Magical Realism and Its Pioneers",
        "Postmodern Playfulness and Intertextuality",
        "Responses to Modernism and the Fragmentation of Narrative"
      ],
    }
  },
  "Narrative Techniques": {
    subcategories: {
      "Point of View": [
        "Introduction to Narrative Perspectives",
        "First-Person Narrative: Intimacy and Bias",
        "Third-Person Omniscient: The All-Knowing Narrator",
        "Third-Person Limited: Through the Character's Eyes",
        "Second-Person Narrative and Reader Engagement",
        "Multiple Points of View and Nonlinear Storytelling"
      ],
      "Stream of Consciousness": [
        "Defining Stream of Consciousness Technique",
        "Pioneers of the Technique: Woolf and Joyce",
        "Stream of Consciousness in Poetry",
        "Character Psychology and Depth in Narration",
        "Challenges and Rewards of Stream of Consciousness",
        "Modern Applications of the Technique"
      ],
      "Unreliable Narrator": [
        "Unreliable Narrators and Reader Trust",
        "Famous Unreliable Narrators in Literature",
        "The Psychology Behind the Unreliable Narrator",
        "Unreliability and Plot Twists",
        "Varieties of Unreliable Narration",
        "The Role of the Reader in Unreliable Narration"
      ],
      "Metafiction": [
        "Metafiction: Self-Aware Storytelling",
        "Historical Development of Metafiction",
        "Metafiction's Role in Literature and Critique",
        "Narrative Layers and Complexity in Metafiction",
        "Metafiction and Humor",
        "Contemporary Works and the Influence of Metafiction"
      ],
      "Intertextuality": [
        "Intertextuality in Literature: An Overview",
        "Allusions and References: Connecting Texts",
        "Intertextuality in Modernist Literature",
        "Adaptation and Retelling as Intertextuality",
        "Parody, Pastiches, and Intertextual Comedy",
        "Intertextuality in Postmodern Works"
      ],
    }
  },
  "Poetry Forms": {
    subcategories: {
      "Sonnet": [
        "Understanding the Structure of Sonnets",
        "Exploring the Italian (Petrarchan) Sonnet",
        "Mastering the English (Shakespearean) Sonnet",
        "Contemporary Sonnets and Variations",
        "Writing Your Own Sonnet: A Workshop"
      ],
      "Haiku": [
        "The Essence of Haiku: Brevity and Nature",
        "The Rules of Traditional Haiku",
        "Modern Haiku and Breaking the Rules",
        "Haiku Across Cultures",
        "Composing Haiku: A Practical Exercise"
      ],
      "Limerick": [
        "Limerick: Humor and Rhythm",
        "The AABBA Rhyme Scheme of Limericks",
        "Notable Limericks and Their Creators",
        "Limericks in Children's Literature",
        "Crafting Witty Limericks: Practice Session"
      ],
      "Ode": [
        "Introduction to the Ode Form",
        "Classical Odes: Structure and Style",
        "Romantic Odes: Emotion and Nature",
        "Reading and Analyzing Famous Odes",
        "Writing an Ode: Honoring the Form"
      ],
      "Free Verse": [
        "Free Verse Poetry: Defining Characteristics",
        "The Liberation from Verse: Walt Whitman and Beyond",
        "Free Verse vs. Traditional Forms: An Analysis",
        "Contemporary Free Verse Poets",
        "Composing Free Verse: Techniques and Tips"
      ],
      "Epic": [
        "Epic Poetry: Heroes and Histories",
        "Studying Classic Epics: From 'Gilgamesh' to 'The Odyssey'",
        "Epic Themes and the Human Experience",
        "Modern Epics: Adapting the Form",
        "Beginning Your Epic: Outlining and Drafting"
      ],
      "Ballad": [
        "The Ballad Tradition: Stories in Song",
        "The Ballad Stanza and Its Use",
        "Historical and Romantic Ballads",
        "Ballads in Modern Music",
        "Creating Your Own Ballad Narrative"
      ],
      "Villanelle": [
        "Villanelle: The Power of Refrain",
        "Analyzing 'Do not go gentle into that good night'",
        "The Fixed Form of Villanelle and Its Variations",
        "Writing a Villanelle: Embracing Repetition and Rhyme",
        "Contemporary Villanelles and How the Form Has Evolved"
      ],
    }
  },
  "Literary Devices": {
    subcategories: {
      "Metaphor and Simile": [
        "Introduction to Figurative Language",
        "Understanding Metaphors: Concepts and Applications",
        "Exploring Similes: Comparisons and Creativity",
        "Analyzing Metaphors and Similes in Literature",
        "Practical Exercises in Crafting Metaphors and Similes"
      ],
      "Alliteration and Assonance": [
        "The Music of Words: Alliteration and Assonance",
        "Exploring Sound Devices in Poetry and Prose",
        "Practice in Alliteration and Assonance",
        "The Effects of Sound Devices on Rhythm and Mood",
        "Writing with Alliteration and Assonance"
      ],
      "Hyperbole": [
        "Understanding Hyperbole and its Purposes",
        "Identifying Hyperbole in Everyday Language and Literature",
        "The Role of Exaggeration in Satire",
        "Exercises in Writing Hyperbolic Statements",
        "Balancing Hyperbole for Effective Communication"
      ],
      "Irony and Sarcasm": [
        "Irony: Types and Functions",
        "Sarcasm vs. Verbal Irony: Nuances and Usage",
        "Detecting Irony and Sarcasm in Texts",
        "Employing Irony and Sarcasm in Creative Writing",
        "The Impact of Irony and Sarcasm on Tone and Meaning"
      ],
      "Symbolism": [
        "The Basics of Symbolism in Literature",
        "Symbols Across Cultures and Contexts",
        "Decoding Symbolic Meanings in Texts",
        "Creating Your Own Symbols in Writing",
        "Complex Symbolism in Modern Literature"
      ],
      "Foreshadowing": [
        "Introduction to Foreshadowing",
        "Examples of Foreshadowing in Literature and Film",
        "Techniques for Creating Suspense with Foreshadowing",
        "Writing Exercises for Foreshadowing",
        "Subtle vs. Obvious Foreshadowing: Finding the Balance"
      ],
      "Personification": [
        "Personification: Bringing Ideas to Life",
        "Analyzing Personification in Poetry and Prose",
        "Practicing Personification: Writing Exercises",
        "The Effectiveness of Personification in Storytelling",
        "Personification in Non-Fiction: A Creative Approach"
      ],
    }
  },
  "Critical Reading": {
    subcategories: {
      "Textual Analysis": [
        "Introduction to Textual Analysis: Techniques and Methods",
        "Close Reading: Analyzing Language and Structure",
        "Interpreting Themes and Arguments in Texts",
        "Applying Textual Analysis to Various Genres",
        "Workshop: Conducting Your Own Textual Analysis"
      ],
      "Contextual Analysis": [
        "Understanding Contextual Analysis: Background and Basics",
        "Historical and Cultural Contexts in Literature",
        "Incorporating Contextual Insights into Literary Analysis",
        "Case Studies: Contextual Analysis of Key Texts",
        "Developing a Contextual Analysis Essay"
      ],
      "Comparative Literature": [
        "Foundations of Comparative Literature",
        "Comparing Literary Works Across Cultures and Time Periods",
        "Themes and Techniques in Global Literature",
        "Case Study: A Comparative Analysis of Literary Works",
        "Creating a Comparative Literature Review"
      ],
      "Thematic Analysis": [
        "Identifying Themes in Literary Works",
        "Techniques for Uncovering Themes",
        "Thematic Analysis Across Different Genres",
        "Developing a Thematic Essay",
        "Workshop: Thematic Analysis of a Selected Text"
      ],
      "Character Analysis": [
        "Exploring Character Analysis: Methods and Importance",
        "Understanding Character Motivations and Development",
        "Analyzing Character Relationships and Dynamics",
        "Character Analysis in Complex Narratives",
        "Workshop: Conducting a Detailed Character Study"
      ],
    }
  },
  "Writing and Composition": {
    subcategories: {
      "Creative Writing": [
        "Foundations of Creative Writing: Exploring Your Voice",
        "Character Development and Storytelling in Creative Writing",
        "Setting, Plot, and Conflict: Building Your Story",
        "Revising and Editing Creative Works",
        "Publishing Your Creative Writing: Next Steps"
      ],
      "Essay Writing": [
        "Introduction to Academic Essay Writing",
        "Thesis Statements and Essay Structure",
        "Developing Arguments and Supporting Evidence",
        "The Art of Persuasive Essay Writing",
        "Revising, Editing, and Polishing Your Essay"
      ],
      "Research Papers": [
        "Getting Started with Academic Research",
        "Developing a Research Question and Thesis",
        "Effective Research Strategies and Note-Taking",
        "Organizing and Writing the Research Paper",
        "Citing Sources and Avoiding Plagiarism"
      ],
      "Literary Criticism": [
        "Introduction to Literary Criticism and Theory",
        "Applying Critical Theories to Literary Texts",
        "Developing a Literary Criticism Essay",
        "Workshop: Peer Review and Feedback in Literary Criticism",
        "Exploring Contemporary Issues in Literary Criticism"
      ],
      "Poetry Writing": [
        "Poetry Writing Basics: Forms and Techniques",
        "Imagery and Sound in Poetry",
        "The Process of Crafting a Poem",
        "Workshopping Your Poetry: Feedback and Revisions",
        "The Publication Process for Poets"
      ],
      "Storytelling Techniques": [
        "The Art of Storytelling: Basics and Techniques",
        "Narrative Structure and Plot Development",
        "Character and Setting in Storytelling",
        "Oral vs. Written Storytelling: Understanding the Differences",
        "Using Storytelling to Engage and Persuade"
      ],
    }
  },
  "World Literature": {
    subcategories: {
      "African Literature": [
        "Introduction to African Literature: Themes and Genres",
        "Colonial and Postcolonial African Literature",
        "Prominent African Authors and Their Works",
        "Exploring African Oral Traditions",
        "Contemporary African Literature and Global Perspectives"
      ],
      "Asian Literature": [
        "Overview of Asian Literary Traditions",
        "Classical Literature of China, Japan, and India",
        "Modern Asian Literature",
        "Asian Literature in the Global Context"
      ],
      "European Literature": [
        "Foundations of European Literature: Ancient to Medieval",
        "Renaissance and Enlightenment European Literature",
        "Romanticism to Modernism: Evolutions in European Literature",
        "Post-War European Literature: Themes and Innovations"
      ],
      "Latin American Literature": [
        "Introduction to Latin American Literature: Origins and Identity",
        "Magical Realism and Other Distinctive Genres",
        "Notable Latin American Authors and Works"
      ],
      "Middle Eastern Literature": [
        "Ancient Texts and Epic Traditions of the Middle East",
        "Classical and Medieval Literature of the Middle East",
        "The Middle East in World Literature: Intertextual Connections"
      ],
      "North American Literature": [
        "Early American and Canadian Literary Traditions",
        "The Evolution of American Literature: Romanticism to Realism",
        "Modernist Movements in North American Literature"
      ],
      "Oceanian Literature": [
        "Introduction to Oceanian Literature: Voices from the Pacific",
        "Indigenous Storytelling and Oral Traditions",
        "Contemporary Oceanian Authors and Their Global Influence"
      ],
    }
  },
  "Influential Authors": {
    subcategories: {
      "England": [
        "Geoffrey Chaucer: The Father of English Literature",
        "William Shakespeare: The Bard of Avon",
        "Jane Austen: Social Commentary through Romance",
        "Charles Dickens: The Victorian Era's Voice",
        "Virginia Woolf: Modernist Literature Pioneer"
      ],
      "Russia": [
        "Alexander Pushkin: The Russian Shakespeare",
        "Leo Tolstoy: Epic Novels and Moral Philosophy",
        "Fyodor Dostoevsky: Human Psychology and Christian Existentialism",
        "Anton Chekhov: Mastery in Short Story"
      ],
      "France": [
        "Victor Hugo: Romanticism and Social Justice",
        "Gustave Flaubert: Literary Realism",
        "Marcel Proust: In Search of Lost Time",
        "Albert Camus: Philosophy and Absurdism"
      ],
      "United States": [
        "Mark Twain: Humor and American Culture",
        "Ernest Hemingway: The Iceberg Theory",
        "F. Scott Fitzgerald: The Jazz Age",
        "Toni Morrison: Exploring American Identity"
      ],
      "Japan": [
        "Murasaki Shikibu: Pioneering Japanese Narrative",
        "Natsume Sōseki: Bridging Tradition and Modernity",
        "Kenzaburo Oe: Post-War Japanese Narrative",
        "Haruki Murakami: Contemporary Japanese Fiction"
      ],
      "Germany": [
        "Johann Wolfgang von Goethe: Sturm und Drang",
        "Thomas Mann: Analyzing German Society",
        "Hermann Hesse: Individuality and Spirituality",
        "Franz Kafka: Existentialism and Surrealism"
      ],
    }
  },
};


function LitPage() {
  const showLogoutModal = useInactivityTimer()
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const difficultyLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const onMainCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
    setSelectedSubcategory(null); // Reset subcategory selection when a different main category is clicked
  };

  const onSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(selectedSubcategory === subcategory ? null : subcategory);
  };

  const onSubSubcategoryClick = (subSubcategoryName, mainCategoryName, subCategoryName) => {
    // Check if the currently selected lesson matches the clicked item and toggle off if so
    if (selectedLesson && selectedLesson.subSubcategoryName === subSubcategoryName) {
      setSelectedLesson(null);
    } else {
      setSelectedLesson({
        subSubcategoryName,
        mainCategoryName,
        subCategoryName
      });
    }
  };

  const onDifficultyClick = async (difficulty) => {
    const { subSubcategoryName, mainCategoryName, subCategoryName } = selectedLesson;
    try {
      const userId = localStorage.getItem('userID');
      const response = await axios.post(`${apiBaseUrl}/users/${userId}/log-activity`, {
        lesson_name: `${subCategoryName}: ${subSubcategoryName}`,
        subject: 'Literature',
        category: mainCategoryName,
        difficulty: difficulty
      }, {
        withCredentials: true
      });
      console.log('Log activity response:', response.data.lesson_id);
      const lessonId = response.data.lesson_id;
      navigate(`/lesson/${subSubcategoryName}`, { state: {
        subjectName: 'Literature',
        categoryName: mainCategoryName,
        subCategoryName: subCategoryName,
        difficulty: difficulty,
        lessonId: lessonId
      }});
      setSelectedLesson(null); // Reset lesson selection after navigating
    } catch (error) {
      console.error('Error logging user activity', error);
    }
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="lit-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
      {showLogoutModal && <LogoutModal />}
      <div className="topic-header">
        <img src={logoImage} alt="iLUMINA Logo" className="topic-logo" onClick={goToSubject} />
        <UserIcon className="user-profile" style={{ fill:'#517d90' }} onClick={goToUserPage} />
      </div>
      <div className="lit-container">
        {Object.entries(lit_lessons).map(([mainCategory, data]) => (
          <div className="lit-category" key={mainCategory}>
            <h2 onClick={() => onMainCategoryClick(mainCategory)}>{mainCategory}</h2>
            <ul>
              {Object.entries(data.subcategories).map(([subcategory, subItems]) => (
                <li key={subcategory} onClick={(e) => {
                  e.stopPropagation(); // Stop propagation to prevent mainCategory click
                  onSubcategoryClick(subcategory);
                }} className={selectedSubcategory === subcategory ? 'subcategory open' : 'subcategory'}>
                  {subcategory}
                  <ul>
                    {subItems.map(subItem => (
                      <li key={subItem} onClick={(e) => {
                        e.stopPropagation();  // Prevent the subcategory's click event
                        onSubSubcategoryClick(subItem, mainCategory, subcategory);
                      }}>
                        {subItem}
                        {selectedLesson && selectedLesson.subSubcategoryName === subItem && (
                          <div className="difficulty-selector">
                            {difficultyLevels.map(level => (
                              <button key={level} onClick={(e) => {
                                e.stopPropagation(); // Prevent the li click event
                                onDifficultyClick(level);
                              }}>
                                {level}
                              </button>
                            ))}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="back-button" onClick={goToSubject}>Back to Subjects</button>
    </div>
  );

}
export default LitPage;
