import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function CheckoutProcessingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  console.log(sessionId)
  // Adjust the delay (in milliseconds) to allow the backend to process the payment and update the database
  const checkDelay = 10000; // 10 seconds

  useEffect(() => {
    const checkSessionStatus = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/session-status?session_id=${sessionId}`, {
          withCredentials: true
        });

        // Process the payment status
        if (response.data.status && response.data.status.trim().toLowerCase() === 'complete') {
          navigate('/subject-selection'); // Redirect to the desired page
        } else {
          navigate('/error'); // Handle other statuses as error
        }
      } catch (error) {
        console.error('Error checking session status:', error);
        navigate('/error'); // Navigate to an error page on error
      }
    };

    if (sessionId) {
      setTimeout(checkSessionStatus, checkDelay); // Single timeout
    }
  }, [sessionId, navigate]);

  return <div>Processing Payment...</div>;
}

export default CheckoutProcessingPage;




