import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasPaid, setHasPaid] = useState(false);
  //const isAuthenticated = !!localStorage.getItem('token');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  console.log(apiBaseUrl)

  useEffect(() => {
    async function fetchPaymentStatus() {
      try {
        const response = await axios.get(`${apiBaseUrl}/user-status/`, {
          withCredentials: true
        });
        const { hasPaid } = response.data;
        setHasPaid(hasPaid);
        setIsAuthenticated(true);
        console.log(hasPaid)
        //localStorage.setItem('hasPaid', hasPaid);
      } catch (error) {
        console.error('Failed to fetch payment status', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPaymentStatus();
  }, []);

    //if (isAuthenticated) {
      //fetchPaymentStatus();
    //} else {
      //setIsLoading(false);
    //}
  //}, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!hasPaid) {
    return <Navigate to="/select-plan" />;
  }

  return children;
}

export default ProtectedRoute;



