import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './LessonPage.css';
import UserIcon from '../shared/UserIcon';
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png';

function LessonPage() {
  const showLogoutModal = useInactivityTimer()
  const navigate = useNavigate();
  const [content, setContent] = useState({ lesson: '', assessment: '' });
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const { lessonName } = useParams();
  const location = useLocation()
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  //Extract subjectName from the location state
  const subjectName = location.state?.subjectName || 'No Subject';
  const categoryName = location.state?.subCategoryName || 'No Sub-category';
  const difficulty = location.state?.difficulty || 'No Difficulty'

  useEffect(() => {
    const eventSource = new EventSource(`${apiBaseUrl}/stream/${lessonName}?category=${encodeURIComponent(categoryName)}&subject=${encodeURIComponent(subjectName)}&difficulty=${encodeURIComponent(difficulty)}`);

    let updatedContent = '';

    eventSource.onmessage = (event) => {
        if (event.data.includes("[End of Stream]")) {
            console.log("Stream closed gracefully");
            eventSource.close(); // Close the EventSource gracefully
        } else {
            updatedContent += event.data;
            processContent(updatedContent);
        }
    };

    eventSource.onerror = (error) => {
        // Log any errors that occur while streaming
        console.error("EventSource error:", error);

        // Close the EventSource to prevent further errors
        eventSource.close();
    };


    return () => eventSource.close();
  }, [lessonName, categoryName, subjectName]);

  const processContent = (updatedContent) => {
    let finalContent = updatedContent.replace(/^data: /gm, '').trim();
    // Ensure there is a space after each period not followed by a whitespace or end of line
    finalContent = finalContent.replace(/(?<=[!?,])(?=[^\s])/g, ' ');
    finalContent = finalContent.replace(/(?<=\.(?!\d))\B/g, ' ');
    // Convert double newlines to paragraph breaks first
    finalContent = finalContent.replace(/<p>(?!<\/p>)/g, '</p><p>');
    finalContent = finalContent.replace(/\n/g, '<br>');
    // Insert line breaks before each number in the numbered list
    finalContent = finalContent.replace(/(?<=[:\.'])\s*(\b[1-9]\b|\b1[0-9]\b|\b20\b)\.\s/g, '</p><p>$1. ');
    // Insert line breaks for lettered lists (a) or (b), etc.
    finalContent = finalContent.replace(/(?<=[:\.])\s*([a-z])\)\s/g, '\n\n$1) ');
    // Insert line breaks before "Example Problem:" and "Solution:"
    finalContent = finalContent.replace(/Example Problem:/g, '</p><p><strong>Example Problem:</strong></p><p>');
    finalContent = finalContent.replace(/Solution:/g, '</p><p><strong>Solution:</strong></p><p>');
    // Convert text surrounded by ** to bold
    finalContent = finalContent.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Bold the numbers and text up to a colon in numbered lists
    finalContent = finalContent.replace(/(<p>)([1-9]|1[0-9]|20)\. ([^:]+?:)/g, function(match, p1, p2, p3) {
      // Count the number of spaces in p3, assuming spaces as word separators
      var wordCount = (p3.match(/\s+/g) || []).length;

      // If there are 10 or fewer spaces (hence, words), apply the bold formatting
      if (wordCount <= 10) {
        return p1 + '<strong>' + p2 + '. ' + p3 + '</strong>';
      } else {
        return match; // return the original string if the condition is not met
      }
    });
    // Remove 'Lesson:' if it exists
    finalContent = finalContent.replace(/Lesson:/g, '');

  const assessmentQuestionRegex = /assessment question:/i;
    if (assessmentQuestionRegex.test(finalContent)) {
      const parts = finalContent.split(assessmentQuestionRegex);
      const lessonPart = parts[0].trim();
      const assessmentPart = parts[1].trim();
      setContent({ lesson: lessonPart, assessment: assessmentPart });
    } else {
      setContent(prev => ({ ...prev, lesson: finalContent }));
    }
  };

  const updateActivity = async (lessonId, completed) => {
    try {
      const response = await fetch(`${apiBaseUrl}/lessons/${lessonId}/update-activity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ completed: completed }),
        credentials: 'include'
      });

      if (!response.ok) {
        console.error('Failed to update activity:', await response.text());
      }
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback(''); // Clear feedback before starting a new stream

    const feedbackSource = new EventSource(`${apiBaseUrl}/evaluate/${lessonName}?category=${encodeURIComponent(categoryName)}&subject=${encodeURIComponent(subjectName)}&answer=${encodeURIComponent(userAnswer)}&question=${encodeURIComponent(content.assessment)}`);
    let accumulatedFeedback = '';

    feedbackSource.onmessage = (event) => {
      if (event.data.includes("[End of Stream]")) {
        console.log("Stream closed gracefully");
        feedbackSource.close();
      } else {
        accumulatedFeedback += event.data;
        processFeedback(accumulatedFeedback); // Process feedback using a similar approach to content
      }
    };

    feedbackSource.onerror = (error) => {
      console.error("Feedback EventSource failed:", error);
      feedbackSource.close();
    };

    // No need for an explicit close here; it will be managed by the lifecycle

    updateActivity(location.state?.lessonId,true);
  };

  const enhanceFormulaSpacing = (text) => {
    // Handle inline delimiters
    text = text.replace(/([^$\\])(\$\$?)(?=\S)/g, '$1 $2');
    text = text.replace(/(?<=\S)(\$\$?)([^$\\])/g, '$1 $2');

    // Handle display delimiters
    text = text.replace(/([^\\])(\\\[|\\\(|\\begin\{[a-z]*\*?\})/g, '$1 $2');
    text = text.replace(/(\\\]|\\\)|\\end\{[a-z]*\*?\})([^\\])/g, '$1 $2');

    // Ensure block formulas are on their own line
    text = text.replace(/\\\[.*?\\\]|\\begin\{[a-z]*\*?\}.*?\\end\{[a-z]*\*?\}/g, (match) => `<div class="mathjax-block">${match}</div>`);

    return text;
  };

  const processFeedback = (accumulatedFeedback) => {
    let finalFeedback = accumulatedFeedback.replace(/^data: /gm, '').replace(/\n/g, '<br>');
    // Ensure there is a space after each period not followed by a whitespace or end of line
    finalFeedback = finalFeedback.replace(/(?<=[!?,])(?=[^\s])/g, ' ');
    finalFeedback = finalFeedback.replace(/(?<=\.(?!\d))\B/g, ' ');
    // Convert double newlines to paragraph breaks first
    finalFeedback = finalFeedback.replace(/<p>(?!<\/p>)/g, '</p><p>');
    finalFeedback = finalFeedback.replace(/\n/g, '<br>');
    // Enhance spacing after formulas
    finalFeedback = enhanceFormulaSpacing(finalFeedback);
        // Check if the finalFeedback ends with "None" and remove it
    if (finalFeedback.endsWith("None<br>")) {
      // Remove the last occurrence of "None<br>"
      finalFeedback = finalFeedback.substring(0, finalFeedback.lastIndexOf("None<br>"));
    } else if (finalFeedback.endsWith("None")) {
      // If it ends with "None" without <br>, just in case
      finalFeedback = finalFeedback.substring(0, finalFeedback.lastIndexOf("None"));
    }
    // Insert line breaks before each number in the numbered list
    finalFeedback = finalFeedback.replace(/(?<=[:\.'])\s*(\b[1-9]\b|\b1[0-9]\b|\b20\b)\.\s/g, '</p><p>$1. ');
    // Convert text surrounded by ** to bold
    finalFeedback = finalFeedback.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Bold the numbers and text up to a colon in numbered lists
    finalFeedback = finalFeedback.replace(/(<p>)([1-9]|1[0-9]|20)\. ([^:]+?:)/g, function(match, p1, p2, p3) {
      // Count the number of spaces in p3, assuming spaces as word separators
      var wordCount = (p3.match(/\s+/g) || []).length;

      // If there are 10 or fewer spaces (hence, words), apply the bold formatting
      if (wordCount <= 10) {
        return p1 + '<strong>' + p2 + '. ' + p3 + '</strong>';
      } else {
        return match; // return the original string if the condition is not met
      }
    });
    setFeedback(finalFeedback);
  };

  // Adjust useEffect for MathJax to run after feedback update
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [content, feedback]);


  const handleEndLessonClick = () => {
    // Logic for ending the lesson
    navigate(-1)
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };


  return (
    <div className="lesson-page">
      {showLogoutModal && <LogoutModal />}
      <header className="lesson-header">
        <img src={logoImage} alt="Logo" className="lesson-logo" onClick={goToSubject}/>
        <UserIcon className="user-profile-icon" style={{ fill: '#517d90' }} onClick={goToUserPage}/>
        <h1 className="lesson-title">{lessonName}</h1>
      </header>
      <main className="lesson-content">
        <h2>Lesson:</h2>
        <div dangerouslySetInnerHTML={{ __html: content.lesson }} />
        {content.assessment && (
        <form onSubmit={handleSubmit} className="answer-form">
          <div className="assessment-part">
            <h2>Assessment Question:</h2>
            <div dangerouslySetInnerHTML={{ __html: content.assessment.replace(/\n/g, '<br>') }} />
            <div className="answer-container">
              <textarea
                className="answer-input"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="Your answer..."
                rows={2} /* This sets the minimum number of rows */
              />
              <button type="submit" className="submit-icon">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
        )}
        {feedback && (
          <div className="feedback" dangerouslySetInnerHTML={{ __html: feedback }} />
        )}
      </main>
      <footer className="lesson-footer">
        {/* You may choose to keep or comment out footer buttons based on your testing needs */}
        <button className="lesson-button end-lesson" onClick={handleEndLessonClick}>End Lesson</button>
      </footer>
    </div>
  );
}


export default LessonPage;

