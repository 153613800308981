import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentOptionPage.css';
import axios from 'axios';

function PaymentOptionPage() {
  const [selectedPlan, setSelectedPlan] = useState('individual_monthly');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
    setSelectedPlan(
      selectedPlan.replace(/_(monthly|yearly)$/, `_${cycle}`)
    );
  };

  const handleProceed = async (plan) => {
    const userID = localStorage.getItem('userID');
    //const token = localStorage.getItem('token');

    // Check if the user is authenticated by verifying token and userID
    if (!userID) {
      navigate('/signup');
      return;
    }

    // Set the is_family_plan status before proceeding
    try {
      console.log(`${apiBaseUrl}/update-plan-type/`);
      await axios.post(`${apiBaseUrl}/update-plan-type/`, {
        user_id: userID,
        is_family_plan: plan.includes('family'),
      }, {
        withCredentials: true
      });
    } catch (error) {
      console.error("Error updating plan type:", error);
    }

    navigate('/payment', { state: { plan } });
  };

  return (
    <div className="payment-options-container">
      <h1>Select Your Plan</h1>
      <div className="billing-toggle">
        <button
          className={`toggle-button ${billingCycle === 'monthly' ? 'active' : ''}`}
          onClick={() => handleBillingCycleChange('monthly')}
        >
          Monthly
        </button>
        <button
          className={`toggle-button ${billingCycle === 'yearly' ? 'active' : ''}`}
          onClick={() => handleBillingCycleChange('yearly')}
        >
          Yearly
        </button>
      </div>
      <div className="pricing-table">
        <div className={`plan ${selectedPlan.includes('individual') ? 'selected' : ''}`}>
          <h2>Individual</h2>
          <p>{billingCycle === 'monthly' ? '$19.99 / month' : '$204.00 / year'}</p>
          <ul>
            <li>All access to lesson content</li>
          </ul>
          <button onClick={() => handleProceed(`individual_${billingCycle}`)}>
            {billingCycle === 'monthly' ? 'Subscribe Monthly' : 'Subscribe Yearly'}
          </button>
        </div>
        <div className={`plan ${selectedPlan.includes('family') ? 'selected' : ''}`}>
          <h2>Family</h2>
          <p>{billingCycle === 'monthly' ? '$59.99 / month' : '$610.00 / year'}</p>
          <ul>
            <li>All access to lesson content</li>
            <li>4 additional profiles included</li>
          </ul>
          <button onClick={() => handleProceed(`family_${billingCycle}`)}>
            {billingCycle === 'monthly' ? 'Subscribe Monthly' : 'Subscribe Yearly'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentOptionPage;



