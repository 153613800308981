// LogoutModal.js
import React from 'react';
import './LogoutModal.css';

function LogoutModal() {
  return (
    <div className="logout-modal-overlay">
      <div className="logout-modal">
        <h2>Session Timeout</h2>
        <p>You have been logged out due to inactivity. Please sign in again.</p>
      </div>
    </div>
  );
}

export default LogoutModal;
