import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './SciencePage.css';
import backgroundImg from '../../assets/science_background.png'; // Replace with the path to your background image
import UserIcon from '../shared/UserIcon'; // Import the UserIcon component
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png';
import axios from "axios"; // Replace with the path to your logo image


// A sample data structure for lessons, replace with actual data
export const science_lessons = {
  "Physics": {
    subcategories: {
      "Classical Mechanics": [
        "Introduction to Motion: Kinematics",
        "Newton's Laws of Motion",
        "Work, Energy, and Power",
        "Momentum and Collisions",
        "Circular Motion and Gravitation",
        "Oscillations and Waves"
      ],
      "Electromagnetism": [
        "Electrostatics: Charge and Electric Fields",
        "Electric Potential and Capacitance",
        "Current, Resistance, and Electromotive Force",
        "Direct and Alternating Current Circuits",
        "Magnetic Fields and Electromagnetic Induction",
        "Maxwell's Equations and Electromagnetic Waves"
      ],
      "Thermodynamics": [
        "Temperature and Heat",
        "Thermodynamic Systems and the First Law",
        "The Second and Third Laws of Thermodynamics",
        "Entropy and Free Energy",
        "Phase Transitions and Thermodynamic Equilibrium",
        "Statistical Mechanics"
      ],
      "Quantum Mechanics": [
        "Wave-Particle Duality and the Schrödinger Equation",
        "Quantum States and the Probability Interpretation",
        "Quantum Tunneling and the Uncertainty Principle",
        "Quantum Numbers and Atomic Structure",
        "Molecular Bonding and Spectroscopy",
        "Quantum Field Theory Concepts"
      ],
      "Relativity": [
        "Introduction to Special Relativity",
        "Time Dilation and Length Contraction",
        "Simultaneity and the Lorentz Transformation",
        "Energy and Momentum in Special Relativity",
        "Introduction to General Relativity",
        "Spacetime Curvature and Black Holes"
      ],
      "Particle Physics": [
        "Fundamental Particles and Forces",
        "The Standard Model of Particle Physics",
        "Symmetries and Conservation Laws",
        "Particle Accelerators and Detectors",
        "Neutrino Physics and Experiments",
        "Beyond the Standard Model: Supersymmetry and String Theory"
      ],
      "Nuclear Physics": [
        "Nuclear Structure and Stability",
        "Radioactivity and Decay Processes",
        "Nuclear Reactions and Energy Production",
        "Nuclear Fission and Fusion",
        "Applications of Nuclear Physics: Medicine and Energy",
        "Fundamentals of Nuclear Astrophysics"
      ],
      "Astrophysics": [
        "The Solar System and Planetary Science",
        "Stellar Formation and Evolution",
        "Galaxies and the Milky Way",
        "Cosmology: The Big Bang and the Fate of the Universe",
        "High-Energy Astrophysics: Neutron Stars and Supernovae",
        "Exoplanets and Astrobiology"
      ]
    }
  },
  "Chemistry": {
    subcategories: {
      "General Chemistry": [
        "Atomic Structure and the Periodic Table",
        "Chemical Bonds and Molecular Geometry",
        "Stoichiometry and Chemical Reactions",
        "States of Matter and Gas Laws",
        "Chemical Thermodynamics",
        "Chemical Equilibrium and Reaction Kinetics"
      ],
      "Organic Chemistry": [
        "Introduction to Organic Molecules and Functional Groups",
        "Alkanes, Alkenes, and Alkynes",
        "Stereochemistry and Chirality",
        "Substitution and Elimination Reactions",
        "Alcohols, Ethers, and Epoxides",
        "Carbonyl Chemistry: Aldehydes and Ketones"
      ],
      "Inorganic Chemistry": [
        "Descriptive Inorganic Chemistry",
        "Inorganic Synthesis and Reaction Mechanisms",
        "Coordination Chemistry and Transition Metals",
        "Organometallic Chemistry",
        "Solid-State Chemistry and Materials Science",
        "Bioinorganic Chemistry"
      ],
      "Physical Chemistry": [
        "Thermodynamics and Kinetics in Chemistry",
        "Quantum Chemistry and Spectroscopy",
        "Molecular Symmetry and Group Theory",
        "Chemical Dynamics and Reaction Mechanisms",
        "Statistical Thermodynamics",
        "Surface Chemistry and Catalysis"
      ],
      "Analytical Chemistry": [
        "Introduction to Analytical Chemistry",
        "Chemical Separations and Chromatography",
        "Spectroscopic Methods of Analysis",
        "Electroanalytical Techniques",
        "Mass Spectrometry",
        "Quality Assurance and Calibration Methods"
      ],
      "Biochemistry": [
        "Proteins and Enzymes",
        "Carbohydrates and Lipids",
        "Nucleic Acids and the Genetic Code",
        "Bioenergetics and Metabolic Pathways",
        "Signal Transduction and Biomolecular Interactions",
        "Advanced Topics in Molecular Biology"
      ],
      "Environmental Chemistry": [
        "Chemistry of the Atmosphere",
        "Chemistry of Natural Waters",
        "Soil Chemistry and Agricultural Chemistry",
        "Toxicology and Chemical Risk Assessment"
      ],
      "Industrial Chemistry": [
        "Introduction to Chemical Industries",
        "Process Engineering and Design",
        "Industrial Catalysis",
        "Polymer Chemistry and Technology",
        "Pharmaceutical Chemistry and Drug Design",
        "Energy Production and Storage Technologies"
      ]
    }
  },
  "Biology": {
    subcategories: {
      "Cell Biology": [
        "Introduction to Cells and Cell Theory",
        "Cellular Organelles and Their Functions",
        "Cell Membrane and Transport Mechanisms",
        "The Cell Cycle and Cellular Reproduction",
        "Energy and Metabolism: Photosynthesis and Cellular Respiration",
        "Signal Transduction and Cellular Communication"
      ],
      "Genetics": [
        "Mendelian Genetics and Inheritance Patterns",
        "DNA Structure and Replication",
        "Transcription and Translation: Protein Synthesis",
        "Genetic Variation and Mutation",
        "Genetic Engineering and Biotechnology",
        "Population Genetics and Evolutionary Patterns"
      ],
      "Evolution": [
        "The Theory of Evolution and Natural Selection",
        "The History of Life on Earth and Fossil Records",
        "Speciation and Genetic Drift",
        "Evolution of Populations and Habitats",
        "Coevolution and Evolutionary Arms Races",
        "Human Evolution and Anthropology"
      ],
      "Ecology": [
        "Ecosystems and Biomes",
        "Population Ecology: Growth and Regulation",
        "Community Ecology: Interactions Among Species",
        "Energy Flow and Nutrient Cycling",
        "Conservation Biology and Biodiversity"
      ],
      "Microbiology": [
        "Introduction to Microorganisms",
        "Bacterial Structure, Growth, and Metabolism",
        "Viruses and Prions",
        "Immunology and Pathogenesis",
        "Antibiotics and Resistance Mechanisms",
        "Applied and Environmental Microbiology"
      ],
      "Botany": [
        "Plant Anatomy and Morphology",
        "Plant Physiology: Growth and Development",
        "Plant Reproduction and Life Cycles",
        "Plant Genetics and Biotechnology",
        "Plant Ecology and Adaptations",
        "Economic Botany and Ethnobotany"
      ],
      "Zoology": [
        "Diversity of Animal Life",
        "Animal Anatomy and Physiology",
        "Animal Behavior and Communication",
        "Reproductive Strategies and Life Histories",
        "Animal Conservation and Zoogeography",
        "Integrative and Comparative Biology"
      ],
      "Human Anatomy and Physiology": [
        "The Integumentary and Skeletal Systems",
        "Muscular and Nervous Systems",
        "Cardiovascular and Respiratory Systems",
        "Digestive and Excretory Systems",
        "The Endocrine and Reproductive Systems",
        "The Immune System and Human Health"
      ]
    }
  },
  "Earth Science": {
    subcategories: {
      "Geology": [
        "Minerals and Rock Formation",
        "Plate Tectonics and Earth's Interior",
        "Volcanism and Igneous Processes",
        "Weathering, Erosion, and Soil Formation",
        "Sedimentary Processes and Rock Types",
        "Metamorphic Processes and Resources"
      ],
      "Meteorology": [
        "The Atmosphere and Its Composition",
        "Weather Patterns and Systems",
        "Atmospheric Circulation and Climate",
        "Severe Weather and Storm Chasing",
        "Weather Forecasting and Models"
      ],
      "Oceanography": [
        "The World's Oceans and Their Properties",
        "Ocean Circulation and Currents",
        "Marine Life and Oceanic Ecosystems",
        "Waves, Tides, and Coastal Processes",
        "Marine Resources and Ocean Conservation",
        "Marine Geology and Seafloor Mapping"
      ],
      "Environmental Science": [
        "Introduction to Environmental Issues",
        "Ecosystem Dynamics and Conservation",
        "Pollution and Waste Management"
      ],
      "Paleontology": [
        "Fossils and Their Formation",
        "Evolutionary History and the Fossil Record",
        "Paleoecology and Ancient Environments",
        "Mass Extinctions and Their Causes",
        "Human Paleontology and the Origin of Species",
        "Techniques in Paleontological Excavation and Research"
      ],
      "Geochemistry": [
        "Earth's Chemical Composition",
        "Geochemical Cycles",
        "Mineralogy and Crystal Chemistry",
        "Isotope Geochemistry and Geochronology",
        "Geochemical Exploration and Mining"
      ],
      "Planetary Science": [
        "Planetary Formation and the Solar System",
        "Planetary Geology and Surfaces",
        "Atmospheres and Climate of Planets",
        "Planetary Magnetospheres and Space Weather",
        "Comparative Planetology and Exoplanets",
        "Astrobiology and the Search for Life"
      ]
    }
  },
  "Astronomy": {
    subcategories: {
      "Solar System": [
        "The Sun and Solar Activity",
        "Planets and Their Moons",
        "Minor Bodies: Asteroids, Comets, and Meteoroids",
        "Planetary Orbits and Gravitational Interactions",
        "Space Exploration and Missions",
        "The Formation and Evolution of the Solar System"
      ],
      "Stellar Astronomy": [
        "Stellar Formation and Lifecycles",
        "Stellar Spectra and Classification",
        "Stellar Evolution and End States",
        "Binary Stars and Stellar Interactions",
        "Star Clusters and Stellar Populations",
        "Variable Stars and Stellar Phenomena"
      ],
      "Galactic Astronomy": [
        "The Milky Way: Structure and Components",
        "Galactic Dynamics and Evolution",
        "Interstellar Medium and Star Formation",
        "Galactic Nuclei and Supermassive Black Holes",
        "Galaxies and Galaxy Clusters",
        "Dark Matter and Galactic Halos"
      ],
      "Cosmology": [
        "The Big Bang Theory and Cosmic Background Radiation",
        "Cosmic Structure Formation",
        "Dark Energy and the Accelerating Universe",
        "Cosmological Models and Parameters",
        "The Early Universe and Nucleosynthesis",
        "Cosmic Inflation and the Multiverse Theory"
      ],
      "Exoplanets": [
        "Exoplanet Detection Techniques",
        "Characteristics of Exoplanets",
        "Habitable Zones and Planetary Habitability",
        "Exoplanetary Atmospheres and Climates",
        "Exoplanet Diversity and Classification",
        "Future of Exoplanet Research and Missions"
      ],
      "Astrophysical Processes": [
        "Gravitational Forces and Dynamics",
        "Nucleosynthesis and Chemical Evolution",
        "Accretion Disks and Protostellar Objects",
        "Shock Waves and Cosmic Jets",
        "Neutron Stars and Black Holes",
        "Gravitational Waves and Their Detection"
      ],
      "Observational Astronomy": [
        "Telescopes and Observational Techniques",
        "Photometry and Spectroscopy",
        "Astronomical Surveys and Databases",
        "Astronomical Instrumentation and Detectors",
        "Data Analysis and Interpretation",
        "The Future of Observational Astronomy"
      ]
    }
  },
  "Medical Sciences": {
    subcategories: {
      "Basic Medical Sciences": [
        "Human Anatomy: An Introduction",
        "Cell Biology in Medicine",
        "Human Physiology: Systems and Functions",
        "Pathology: Understanding Disease Mechanisms",
        "Pharmacokinetics: Drug Movement Through the Body",
        "Medical Genetics: From DNA to Disease"
      ],
      "Clinical Medicine": [
        "Clinical Examination Skills",
        "Diagnostic Imaging: X-rays, CT Scans, and MRIs",
        "Laboratory Medicine: Interpreting Test Results",
        "Differential Diagnosis: A Systematic Approach",
        "Therapeutics: Treatment Plans for Common Conditions",
        "Emergency Medicine: Acute Care Principles"
      ],
      "Surgery": [
        "Surgical Instruments and Operating Room Setup",
        "Anesthesia Types and Techniques",
        "Surgical Procedures: A Step-by-Step Guide",
        "Postoperative Care and Management",
        "Minimally Invasive Surgery: Techniques and Advantages",
        "Surgical Specialties: A Comprehensive Overview"
      ],
      "Pharmacology": [
        "Pharmacodynamics: How Drugs Act",
        "Drug Receptors and Cellular Response",
        "Therapeutic Drugs and Their Uses",
        "Adverse Drug Reactions and Drug Interactions",
        "Clinical Pharmacology: Drug Prescription and Safety",
        "Biopharmaceuticals and Advanced Drug Delivery"
      ],
      "Public Health": [
        "Epidemiology: Tracking Diseases and Outcomes",
        "Global Health and Infectious Diseases",
        "Chronic Diseases and Lifestyle Medicine",
        "Healthcare Policy and Management",
        "Community Health and Preventive Strategies",
        "Health Promotion and Education"
      ],
      "Neuroscience": [
        "Brain Structure and Neural Networks",
        "Neurotransmitters and Neural Communication",
        "Sensory Systems and Perception",
        "Motor Systems and Movement Control",
        "Cognitive Neuroscience: Memory and Learning",
        "Clinical Neuroscience: Brain Disorders"
      ],
      "Immunology": [
        "The Innate Immune System: First Line of Defense",
        "Adaptive Immunity: B Cells and Antibodies",
        "T Cells: Cell-Mediated Immunity",
        "Immunological Memory and Vaccination",
        "Autoimmunity and Immune Tolerance",
        "Immunotherapy: Harnessing the Immune System"
      ],
      "Genetics and Genomics": [
        "Molecular Genetics: DNA to Proteins",
        "Genomic Technologies and Sequencing",
        "Genetic Disorders and Screening",
        "Population Genetics and Evolutionary Trends",
        "Functional Genomics and Bioinformatics",
        "Personalized Medicine and Genomic Medicine"
      ]
    }
  },
  "Cognitive Science": {
    subcategories: {
      "Neuroscience": [
        "Fundamentals of Neural Science",
        "Sensory and Motor Systems",
        "Neural Bases of Cognitive Functions",
        "Neuroplasticity and Brain Development",
        "Neurological Disorders and Treatments",
        "Cutting-edge Research in Brain Science"
      ],
      "Psychology": [
        "Introduction to Psychological Science",
        "Developmental Psychology Across the Lifespan",
        "Social Psychology: Behavior in Context",
        "Abnormal Psychology and Mental Health",
        "Cognitive Psychology: Thinking and Intelligence",
        "Therapeutic Approaches and Mental Health Treatment"
      ],
      "Artificial Intelligence": [
        "AI Foundations: Machine Learning Basics",
        "Neural Networks and Deep Learning",
        "Natural Language Processing",
        "Robotics and Perception",
        "Ethics in Artificial Intelligence",
        "Future Trends in AI and Machine Intelligence"
      ],
      "Linguistics": [
        "Phonetics and Phonology: Sounds of Speech",
        "Morphology and Syntax: Building Sentences",
        "Semantics and Pragmatics: Meaning in Language",
        "Sociolinguistics: Language in Society",
        "Historical Linguistics: Language Change Over Time",
        "Applied Linguistics and Language Teaching"
      ],
      "Philosophy": [
        "Philosophy of Mind: Consciousness and Cognition",
        "Philosophy of Science: Knowledge and Inquiry",
        "Ethics and Moral Philosophy",
        "Logic and Reasoning: Critical Thinking Skills",
        "Aesthetics: Philosophy of Art and Beauty"
      ]
    }
  },
  "Forensic Science": {
    subcategories: {
      "Crime Scene Investigation": [
        "Principles of Crime Scene Management",
        "Evidence Collection and Preservation Techniques",
        "Fingerprinting and Impression Evidence",
        "Bloodstain Pattern Analysis",
        "Crime Scene Photography"
      ],
      "Forensic Pathology": [
        "Introduction to Autopsy Procedures",
        "Determination of Cause, Manner, and Time of Death",
        "Forensic Toxicology",
        "Injury Analysis and Trauma Assessment",
        "The Role of Forensic Pathology in Legal Cases"
      ],
      "Forensic Anthropology": [
        "Human Skeletal Anatomy",
        "Bone Identification and Analysis",
        "Determining Age, Sex, and Stature from Skeletal Remains",
        "Analysis of Trauma and Pathological Conditions",
        "Reconstruction of Biological Profile and Individual Identification"
      ],
      "Forensic DNA Analysis": [
        "Basics of Molecular Biology for Forensic DNA",
        "DNA Extraction, Quantification, and Amplification",
        "STR Analysis and DNA Profiling",
        "Population Genetics in Forensic DNA Analysis",
        "Legal and Ethical Considerations in Forensic DNA Analysis"
      ],
      "Digital Forensics": [
        "Fundamentals of Computer Systems and Digital Storage",
        "Digital Evidence and Data Recovery Techniques",
        "Network Forensics and Investigating Cybercrimes",
        "Mobile Forensics and Device Analysis",
        "Legal Aspects of Digital Evidence and Report Writing"
      ]
    }
  },
  "Oceanography": {
    subcategories: {
      "Marine Biology": [
        "Diversity of Marine Life",
        "Marine Ecosystems and Food Webs",
        "Adaptations of Marine Organisms",
        "Conservation and Management of Marine Resources",
        "Marine Research Techniques and Methodologies"
      ],
      "Marine Chemistry": [
        "Chemical Properties of Seawater",
        "Biogeochemical Cycles in the Marine Environment",
        "Marine Organic Chemistry",
        "Trace Metals and Marine Pollution",
        "Chemical Interactions Between Ocean and Atmosphere"
      ],
      "Physical Oceanography": [
        "Ocean Circulation and Currents",
        "Waves, Tides, and Coastal Dynamics",
        "Air-Sea Interaction and Climate Modelling",
        "Remote Sensing and Satellite Oceanography",
        "Energy and Resources from the Ocean"
      ],
      "Marine Geology": [
        "Plate Tectonics and Seafloor Spreading",
        "Marine Sediments and Sedimentary Processes",
        "Submarine Volcanism and Hydrothermal Vents",
        "Marine Geological Structures and Resources",
        "Geophysical Methods in Marine Geology"
      ],
      "Paleoceanography": [
        "Reconstructing Ancient Marine Environments",
        "Marine Fossils and Microfossils",
        "Ice Cores and Climate Change",
        "Sea Level Changes and Paleoceanographic Techniques",
        "Ocean's Role in Past Climate Variability"
      ]
    }
  },
  "Food Science": {
    subcategories: {
      "Food Chemistry": [
        "Chemical Composition of Foods",
        "Food Additives and Flavor Chemistry",
        "Chemical Changes During Food Processing",
        "Enzymes in Food Systems",
        "Food Analysis Techniques"
      ],
      "Food Microbiology": [
        "Microbial Ecology of Foods",
        "Pathogens and Spoilage Organisms in Food",
        "Fermentation Science and Technology",
        "Foodborne Illnesses and Safety",
        "Microbiological Analysis of Food Products"
      ],
      "Food Engineering": [
        "Principles of Food Process Engineering",
        "Unit Operations in Food Production",
        "Food Packaging Technology",
        "Food Rheology and Texture",
        "Advances in Food Processing and Technology"
      ],
      "Food Safety": [
        "Hazard Analysis and Critical Control Points (HACCP)",
        "Food Safety Regulations and Standards",
        "Food Inspection and Quality Control",
        "Risk Assessment and Food Toxicology",
        "Emerging Food Safety Issues and Innovations"
      ],
      "Nutritional Science": [
        "Nutrients and Dietary Components",
        "Human Nutrition and Metabolism",
        "Nutritional Needs Throughout the Lifecycle",
        "Diet and Chronic Diseases",
        "Functional Foods and Nutraceuticals"
      ]
    }
  }
};


function SciencePage() {
  const showLogoutModal = useInactivityTimer()
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const difficultyLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const onMainCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
    setSelectedSubcategory(null); // Reset subcategory selection when a different main category is clicked
  };

  const onSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(selectedSubcategory === subcategory ? null : subcategory);
  };

  const onSubSubcategoryClick = (subSubcategoryName, mainCategoryName, subCategoryName) => {
    // Check if the currently selected lesson matches the clicked item and toggle off if so
    if (selectedLesson && selectedLesson.subSubcategoryName === subSubcategoryName) {
      setSelectedLesson(null);
    } else {
      setSelectedLesson({
        subSubcategoryName,
        mainCategoryName,
        subCategoryName
      });
    }
  };

  const onDifficultyClick = async (difficulty) => {
    const { subSubcategoryName, mainCategoryName, subCategoryName } = selectedLesson;
    try {
      const userId = localStorage.getItem('userID');
      const response = await axios.post(`${apiBaseUrl}/users/${userId}/log-activity`, {
        lesson_name: `${subCategoryName}: ${subSubcategoryName}`,
        subject: 'Science',
        category: mainCategoryName,
        difficulty: difficulty
      }, {
        withCredentials: true
      });
      console.log('Log activity response:', response.data.lesson_id);
      const lessonId = response.data.lesson_id;
      navigate(`/lesson/${subSubcategoryName}`, { state: {
        subjectName: 'Science',
        categoryName: mainCategoryName,
        subCategoryName: subCategoryName,
        difficulty: difficulty,
        lessonId: lessonId
      }});
      setSelectedLesson(null); // Reset lesson selection after navigating
    } catch (error) {
      console.error('Error logging user activity', error);
    }
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="science-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
      {showLogoutModal && <LogoutModal />}
      <div className="topic-header">
        <img src={logoImage} alt="iLUMINA Logo" className="topic-logo" onClick={goToSubject} />
        <UserIcon className="user-profile" style={{ fill:'#517d90' }} onClick={goToUserPage} />
      </div>
      <div className="science-container">
        {Object.entries(science_lessons).map(([mainCategory, data]) => (
          <div className="science-category" key={mainCategory}>
            <h2 onClick={() => onMainCategoryClick(mainCategory)}>{mainCategory}</h2>
            <ul>
              {Object.entries(data.subcategories).map(([subcategory, subItems]) => (
                <li key={subcategory} onClick={(e) => {
                  e.stopPropagation(); // Stop propagation to prevent mainCategory click
                  onSubcategoryClick(subcategory);
                }} className={selectedSubcategory === subcategory ? 'subcategory open' : 'subcategory'}>
                  {subcategory}
                  <ul>
                    {subItems.map(subItem => (
                      <li key={subItem} onClick={(e) => {
                        e.stopPropagation();  // Prevent the subcategory's click event
                        onSubSubcategoryClick(subItem, mainCategory, subcategory);
                      }}>
                        {subItem}
                        {selectedLesson && selectedLesson.subSubcategoryName === subItem && (
                          <div className="difficulty-selector">
                            {difficultyLevels.map(level => (
                              <button key={level} onClick={(e) => {
                                e.stopPropagation(); // Prevent the li click event
                                onDifficultyClick(level);
                              }}>
                                {level}
                              </button>
                            ))}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="back-button" onClick={goToSubject}>Back to Subjects</button>
    </div>
  );

}
export default SciencePage;
