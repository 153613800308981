import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, Navigate } from 'react-router-dom';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripe_promise = process.env.REACT_APP_STRIPE_PROMISE;
console.log(stripe_promise)
const stripePromise = loadStripe(`${stripe_promise}`);

const PaymentPage = () => {
  const location = useLocation();
  const plan = location.state?.plan;
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  if (!plan) {
    return <Navigate to="/select-plan" />;
  }

    const fetchClientSecret = async () => {
      const userID = localStorage.getItem('userID');
      console.log('UserId Received', userID)
      const response = await fetch(`${apiBaseUrl}/create-checkout-session/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ plan: plan, user_id: userID }),
        credentials: "include"
      });

      if (!response.ok) {
        console.error('Failed to fetch client secret');
        return ""; // Return empty string in case of error
      }

      const data = await response.json();
      console.log('Received data:', data); // Log data to inspect it
      return data.clientSecret; // Ensure this matches backend's key
    };


  const options = { fetchClientSecret };

  return (
    <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <h1>Complete Your Subscription</h1>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

export default PaymentPage;