import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './MathPage.css';
import backgroundImg from '../../assets/math_background.png'; // Replace with the path to your background image
import UserIcon from '../shared/UserIcon'; // Import the UserIcon component
import useInactivityTimer from "../shared/useInactivityTimer";
import LogoutModal from "../shared/LogoutModal";
import logoImage from '../../assets/ilumina.png';
import axios from "axios"; // Replace with the path to your logo image


// A sample data structure for lessons, replace with actual data
export const math_lessons = {
  "Arithmetic": {
    subcategories: {
      "Basic Operations": [
        "Understanding Addition and Subtraction",
        "Mastering Multiplication and Division",
        "Order of Operations (PEMDAS)",
        "Problem-Solving with Basic Operations"
      ],
      "Fractions and Decimals": [
        "Introduction to Fractions",
        "Operations with Fractions",
        "Understanding Decimals",
        "Converting Fractions to Decimals and Vice Versa",
        "Operations with Decimals"
      ],
      "Percentage and Ratios": [
        "Understanding Percentages",
        "Calculating Percentages in Various Contexts",
        "Introduction to Ratios",
        "Solving Problems with Ratios and Proportions",
        "Applications of Percentages and Ratios"
      ],
      "Exponents and Roots": [
        "Understanding Exponents",
        "Operations with Exponents",
        "Introduction to Square Roots and Other Roots",
        "Simplifying Expressions with Roots",
        "Applications of Exponents and Roots"
      ],
      "Number Theory": [
        "Prime Numbers and Composite Numbers",
        "Greatest Common Divisor (GCD) and Least Common Multiple (LCM)",
        "Introduction to Divisibility Rules",
        "Understanding Modular Arithmetic",
        "Exploring Number Patterns and Properties"
      ]
    }
  },
  "Algebra": {
    subcategories: {
      "Basic Algebraic Operations": [
        "Introduction to Variables and Algebraic Expressions",
        "Combining Like Terms",
        "The Distributive Property",
        "Simplifying Algebraic Expressions"
      ],
      "Equations and Inequalities": [
        "Solving Linear Equations",
        "Applications of Linear Equations",
        "Solving Linear Inequalities",
        "Graphing Inequalities on a Number Line"
      ],
      "Polynomials": [
        "Understanding Polynomials",
        "Adding, Subtracting, and Multiplying Polynomials",
        "Polynomial Division and Long Division",
        "The Remainder and Factor Theorems"
      ],
      "Factoring": [
        "Factoring Out the Greatest Common Factor (GCF)",
        "Factoring Trinomials",
        "Factoring by Grouping",
        "Special Factoring Formulas"
      ],
      "Functions and Graphs": [
        "Understanding Functions and Their Notation",
        "Graphing Linear Functions",
        "Finding the Slope and Intercept of Linear Functions",
        "Graphing Non-Linear Functions"
      ],
      "Quadratic Equations": [
        "Introduction to Quadratic Equations",
        "Solving Quadratic Equations by Factoring",
        "Solving Quadratic Equations by Completing the Square",
        "Solving Quadratic Equations Using the Quadratic Formula"
      ],
      "Systems of Equations": [
        "Solving Systems of Equations by Graphing",
        "Solving Systems of Equations by Substitution",
        "Solving Systems of Equations by Elimination",
        "Applications of Systems of Equations"
      ]
    }
  },
  "Geometry": {
    subcategories: {
      "Euclidean Geometry": [
        "Introduction to Points, Lines, and Planes",
        "Angles and Their Relationships",
        "Triangle Congruence and Similarity",
        "Properties of Polygons",
        "Circle Theorems and Properties"
      ],
      "Coordinate Geometry": [
        "The Coordinate Plane",
        "Distance Formula",
        "Midpoint Formula",
        "Slope and Equation of a Line",
        "Graphing Linear Equations and Circles"
      ],
      "Trigonometry": [
        "Introduction to Trigonometric Ratios",
        "Right Triangle Trigonometry",
        "The Unit Circle",
        "Graphing Trigonometric Functions",
        "Trigonometric Identities and Equations"
      ],
      "Solid Geometry": [
        "Introduction to Three-Dimensional Figures",
        "Surface Area and Volume of Prisms",
        "Surface Area and Volume of Cylinders",
        "Surface Area and Volume of Pyramids and Cones",
        "Surface Area and Volume of Spheres"
      ],
      "Transformations": [
        "Basic Transformations: Translations, Rotations, and Reflections",
        "Dilations and Similarity",
        "Compositions of Transformations",
        "Symmetry and Tessellations",
        "Geometric Proofs with Transformations"
      ]
    }
  },
  "Calculus": {
    subcategories: {
      "Differential Calculus": [
        "Understanding Limits: Concept and Calculation",
        "Exploring Continuity in Functions",
        "Introduction to Derivatives: Slope of Tangent Lines",
        "Rules of Differentiation and Derivative Operations",
        "Applications of Derivatives in Real-Life Scenarios",
        "Understanding the Shape of Graphs and Curve Sketching",
        "Optimization Problems in Economics and Engineering",
        "Introduction to Related Rates Problems"
      ],
      "Integral Calculus": [
        "Understanding the Area Under Curves",
        "The Fundamental Theorem of Calculus",
        "Techniques of Antidifferentiation",
        "Definite Integrals and Properties",
        "Applications of Integrals: Volumes of Solids of Revolution",
        "Techniques of Integration: Substitution to Partial Fractions",
        "Improper Integrals and their Convergence",
        "Applications to Physics and Engineering Problems"
      ],
      "Multivariable Calculus": [
        "Understanding Functions of Several Variables",
        "Partial Derivatives and Gradient Vectors",
        "Double and Triple Integrals for Volume Calculations",
        "Line and Surface Integrals",
        "Divergence and Curl of Vector Fields",
        "Theorems of Green, Stokes, and Gauss",
        "Applications to Fluid Dynamics and Electromagnetism",
        "Introduction to Differential Geometry Concepts"
      ],
      "Differential Equations": [
        "Introduction to Differential Equations",
        "Solving First Order Differential Equations",
        "Solving Second Order Linear Differential Equations",
        "Series Solutions and Special Functions",
        "Laplace Transforms and Their Applications",
        "Systems of Differential Equations",
        "Introduction to Partial Differential Equations",
        "Fourier Series and Boundary Value Problems"
      ]
    }
  },
  "Statistics and Probability": {
    subcategories: {
      "Descriptive Statistics": [
        "Introduction to Types of Data and Data Collection",
        "Measures of Central Tendency: Mean, Median, Mode",
        "Measures of Spread: Range, Variance, Standard Deviation",
        "Data Visualization: Charts and Graphs",
        "Exploratory Data Analysis and Outliers"
      ],
      "Probability Theory": [
        "Basic Probability Concepts and Axioms",
        "Conditional Probability and Bayes' Theorem",
        "Random Variables and Probability Distributions",
        "Expectation, Variance, and Moments",
        "Common Distributions: Binomial, Poisson, and Normal"
      ],
      "Inferential Statistics": [
        "Sampling Distributions and the Central Limit Theorem",
        "Confidence Intervals for Means and Proportions",
        "Hypothesis Testing Fundamentals",
        "Type I and Type II Errors, Power of a Test",
        "ANOVA, Chi-Square, and Non-Parametric Tests"
      ],
      "Regression Analysis": [
        "Simple Linear Regression and Correlation",
        "Multiple Linear Regression and Model Fitting",
        "Assumptions of Regression Models and Diagnostics",
        "Logistic Regression for Categorical Outcomes",
        "Time Series Analysis and Forecasting"
      ],
      "Bayesian Statistics": [
        "Bayes' Theorem and Bayesian Thinking",
        "Prior, Likelihood, and Posterior in Bayesian Analysis",
        "Bayesian Inference and Credible Intervals",
        "Markov Chain Monte Carlo (MCMC) Methods",
        "Bayesian Hierarchical and Mixed Models"
      ]
    }
  },
  "Discrete Mathematics": {
    subcategories: {
      "Set Theory": [
        "Introduction to Sets, Subsets, and Set Operations",
        "Venn Diagrams and Set Identities",
        "Cartesian Products and Power Sets",
        "Countable and Uncountable Sets",
        "Set Theory in Logic and Proof"
      ],
      "Combinatorics": [
        "Fundamental Principles of Counting",
        "Permutations and Combinations",
        "Binomial Theorem and Pascal's Triangle",
        "Advanced Counting Techniques",
        "Generating Functions and Recurrence Relations"
      ],
      "Logic and Boolean Algebra": [
        "Propositional Logic and Logical Connectives",
        "Truth Tables and Logical Equivalence",
        "Predicate Logic and Quantifiers",
        "Proof Techniques and Strategies",
        "Boolean Algebras and Boolean Functions"
      ],
      "Number Theory": [
        "Divisibility, Primes, and Greatest Common Divisors",
        "Congruences and Modular Arithmetic",
        "Cryptographic Applications of Number Theory",
        "Diophantine Equations and Fermat's Theorem",
        "Continued Fractions and Rational Approximations"
      ],
      "Graph Theory": [
        "Introduction to Graphs and Graph Terminology",
        "Eulerian and Hamiltonian Paths",
        "Planar Graphs and Graph Coloring",
        "Network Flows and Matchings",
        "Trees, Spanning Trees, and Minimum Spanning Trees"
      ]
    }
  },
  "Linear Algebra": {
    subcategories: {
      "Matrices and Determinants": [
        "Matrix Operations and Types of Matrices",
        "Determinants and Their Properties",
        "Cramer's Rule and Systems of Linear Equations",
        "Eigenvalues and Eigenvectors",
        "Diagonalization and Matrix Functions"
      ],
      "Vector Spaces": [
        "Definition and Examples of Vector Spaces",
        "Subspaces, Bases, and Dimensions",
        "Linear Independence and Span",
        "Orthogonality and Projections",
        "Inner Product Spaces and Norms"
      ],
      "Linear Transformations": [
        "Definition and Examples of Linear Transformations",
        "The Kernel and Image of a Transformation",
        "Matrix Representations of Transformations",
        "Change of Basis and Similarity",
        "Applications to Differential Equations and Geometry"
      ],
      "Eigenvalues and Eigenvectors": [
        "Finding Eigenvalues and Eigenvectors",
        "Diagonalization and Triangularization",
        "Spectral Theorems",
        "Jordan Canonical Form",
        "Applications in Differential Equations and Stability Analysis"
      ],
      "Systems of Linear Equations": [
        "Solving Systems of Linear Equations",
        "Matrix Approach to Systems and Inverse Matrices",
        "LU Decomposition",
        "Iterative Methods for Systems",
        "Applications in Network Analysis and Modeling"
      ]
    }
  },
  "Mathematical Analysis": {
    subcategories: {
      "Real Analysis": [
        "Sequences and Limits",
        "Continuity and Differentiability",
        "Integration and the Fundamental Theorem of Calculus",
        "Infinite Series and Convergence Tests",
        "Uniform Convergence and Power Series"
      ],
      "Complex Analysis": [
        "Complex Numbers and Functions",
        "Analytic Functions and Cauchy-Riemann Equations",
        "Complex Integration and Cauchy's Theorem",
        "Laurent Series and Residue Theorem",
        "Conformal Mappings and Applications"
      ],
      "Functional Analysis": [
        "Normed Spaces and Banach Spaces",
        "Inner Product Spaces and Hilbert Spaces",
        "Linear Operators and Functionals",
        "Spectral Theory",
        "Applications to Differential and Integral Equations"
      ],
      "Measure Theory": [
        "Measure Spaces and Measurable Functions",
        "Lebesgue Integral",
        "Convergence Theorems",
        "Product Measures and Fubini's Theorem",
        "Probability Measures and Ergodic Theory"
      ],
      "Fourier Analysis": [
        "Fourier Series and Fourier Transform",
        "Properties of Fourier Transforms",
        "The Fourier Inversion Theorem",
        "The Plancherel Theorem and Parseval's Identity",
        "Applications in Signal Processing and PDEs"
      ]
    }
  },
  "Abstract Algebra": {
    subcategories: {
      "Group Theory": [
        "Groups, Subgroups, and Cyclic Groups",
        "Group Homomorphisms and Isomorphisms",
        "Normal Subgroups and Quotient Groups",
        "Group Actions and Sylow Theorems",
        "Classification of Finite Simple Groups"
      ],
      "Ring Theory": [
        "Rings, Integral Domains, and Fields",
        "Ideals and Quotient Rings",
        "Ring Homomorphisms and Chinese Remainder Theorem",
        "Polynomial Rings and Unique Factorization Domains",
        "Modules over Rings"
      ],
      "Field Theory": [
        "Field Extensions and Algebraic Extensions",
        "Splitting Fields and Algebraic Closures",
        "Galois Theory and Solvability by Radicals",
        "Finite Fields and Applications",
        "Transcendental Extensions"
      ],
      "Galois Theory": [
        "Fundamentals of Galois Theory",
        "Galois Extensions and the Fundamental Theorem of Galois Theory",
        "Solvability by Radicals",
        "Cyclotomic Extensions and Kummer Theory",
        "Galois Groups of Polynomials"
      ]
    }
  }
};


function MathPage() {
  const showLogoutModal = useInactivityTimer()
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const difficultyLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const onMainCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
    setSelectedSubcategory(null); // Reset subcategory selection when a different main category is clicked
  };

  const onSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(selectedSubcategory === subcategory ? null : subcategory);
  };

  const onSubSubcategoryClick = (subSubcategoryName, mainCategoryName, subCategoryName) => {
    // Check if the currently selected lesson matches the clicked item and toggle off if so
    if (selectedLesson && selectedLesson.subSubcategoryName === subSubcategoryName) {
      setSelectedLesson(null);
    } else {
      setSelectedLesson({
        subSubcategoryName,
        mainCategoryName,
        subCategoryName
      });
    }
  };

  const onDifficultyClick = async (difficulty) => {
    const { subSubcategoryName, mainCategoryName, subCategoryName } = selectedLesson;
    try {
      const userId = localStorage.getItem('userID');
      const response = await axios.post(`${apiBaseUrl}/users/${userId}/log-activity`, {
        lesson_name: `${subCategoryName}: ${subSubcategoryName}`,
        subject: 'Math',
        category: mainCategoryName,
        difficulty: difficulty
      }, {
        withCredentials: true
      });
      console.log('Log activity response:', response.data.lesson_id);
      const lessonId = response.data.lesson_id;
      navigate(`/lesson/${subSubcategoryName}`, { state: {
        subjectName: 'Math',
        categoryName: mainCategoryName,
        subCategoryName: subCategoryName,
        difficulty: difficulty,
        lessonId: lessonId
      }});
      setSelectedLesson(null); // Reset lesson selection after navigating
    } catch (error) {
      console.error('Error logging user activity', error);
    }
  };

  const goToUserPage = () => {
    navigate('/user-profile'); // Assuming /math is the route for the MathPage
  };

  const goToSubject = () => {
    navigate('/subject-selection'); // Assuming /math is the route for the MathPage
  };

  return (
    <div className="math-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
      {showLogoutModal && <LogoutModal />}
      <div className="topic-header">
        <img src={logoImage} alt="iLUMINA Logo" className="topic-logo" onClick={goToSubject} />
        <UserIcon className="user-profile" style={{ fill:'#517d90' }} onClick={goToUserPage} />
      </div>
      <div className="math-container">
        {Object.entries(math_lessons).map(([mainCategory, data]) => (
          <div className="math-category" key={mainCategory}>
            <h2 onClick={() => onMainCategoryClick(mainCategory)}>{mainCategory}</h2>
            <ul>
              {Object.entries(data.subcategories).map(([subcategory, subItems]) => (
                <li key={subcategory} onClick={(e) => {
                  e.stopPropagation(); // Stop propagation to prevent mainCategory click
                  onSubcategoryClick(subcategory);
                }} className={selectedSubcategory === subcategory ? 'subcategory open' : 'subcategory'}>
                  {subcategory}
                  <ul>
                    {subItems.map(subItem => (
                      <li key={subItem} onClick={(e) => {
                        e.stopPropagation();  // Prevent the subcategory's click event
                        onSubSubcategoryClick(subItem, mainCategory, subcategory);
                      }}>
                        {subItem}
                        {selectedLesson && selectedLesson.subSubcategoryName === subItem && (
                          <div className="difficulty-selector">
                            {difficultyLevels.map(level => (
                              <button key={level} onClick={(e) => {
                                e.stopPropagation(); // Prevent the li click event
                                onDifficultyClick(level);
                              }}>
                                {level}
                              </button>
                            ))}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="back-button" onClick={goToSubject}>Back to Subjects</button>
    </div>
  );

}

export default MathPage;
