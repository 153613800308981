// HelpPage.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HelpPage.css'; // Adjust the path to your CSS file as needed
import logoImage from '../../assets/ilumina.png'; // Adjust the path to your logo as needed

function HelpPage() {
  useEffect(() => {
    // Create and append the script element
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45893146", // Replace with your Portal ID
          formId: "4eb687ed-ce7f-4b9b-b6aa-1b0001349236", // Replace with your Form ID
          target: '#hubspotForm' // ID to place the form
        });
      }
    };

    // Cleanup the script element on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="help-page-container">
      <Link to="/">
        <img src={logoImage} alt="Logo" className="top-left-logo" />
      </Link>
      <h1>Help Page</h1>
      <p>If you need assistance, please use the form below to contact us:</p>
      <div className="hubspot-container" id="hubspotForm"></div>
    </div>
  );
}

export default HelpPage;

